import React, { Component } from "react";
import {
    Form, Input, Button, List, Avatar, Dropdown, Menu,
    Skeleton, message, Row, Col, Layout, PageHeader
} from 'antd';
import { getUserById, updateProfile } from '../services/UserServices';
import { DownOutlined } from '@ant-design/icons';

import { englishText } from '../constants/englishText';
import { dutchText } from '../constants/dutchText';
const { Content } = Layout;

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: 'Loading ...',
            id: 'Loading ...',
            inEdit: false,
            list: [],
            initLoading: true,
            loading: false,
            data: [],
            lang: '',
            uid:0,
            localization: englishText.pages.profile
        }
    }
    componentDidMount() {
        //console.log("Profile mounted");

        let globalLang = localStorage.getItem("LANG") || 'EN';
        if (globalLang === 'EN') {
            this.setState({ lang: 'EN' });
        }
        else if (globalLang === 'NL') {
            this.setState({ lang: 'NL' });
        }

        localStorage.setItem("LANG", this.state.lang);
        //change to get user by id 
        let userObj = {
            "email": '',
            "name": '',
            "role": ''
        };
        let name = localStorage.getItem('UID');
        this.setState({uid:name});
        console.log("Getting User", name);
        getUserById(name).then((resp) => {
            // console.log("we have a user", resp.data);
            userObj.email = resp.data.data.Email;
            userObj.name = resp.data.data.Name;
            userObj.role = resp.data.data.Role;
            this.setState({
                initLoading: false,
                data: [userObj],
                list: [userObj],
            });
        }).catch((err) => {
            console.log("error getting user", err);
        })

    }
    goBacks = () => {
        let hist = this.props.history;
        hist.goBack();
    }
    onFinish = (values) => {
        console.log('Success:', values);
        values.uid=this.state.uid;
        // call api to edit user
        updateProfile(values).then( (res)=>{
            console.log("updated profile",res);
        }).catch( (err)=>{
            console.log("error updating user",err);
        })
    };
    onFinishFailed = (errorInfo) => {
        message.error("Sorry an error occured please try again later");
        setTimeout(() => {
            this.setState({ inEdit: false })
        }, 1000);
        console.log('Failed:', errorInfo);
    };
    setLanguage = (e) => {
        console.log("key clicked",e.key);
        let nLang='';
        if (e.key === '0' || e.key === 0) {
            console.log("here");
            nLang='NL';
            this.setState({ lang: 'NL' });
            this.setState({ localization: dutchText.pages.profile });
        }
        else if (e.key === '1' || e.key === 1) {
            nLang='EN';
            this.setState({ lang: 'EN' });
            this.setState({ localization: englishText.pages.profile });
        }

        console.log("Set language here", nLang);
        localStorage.setItem("LANG", nLang);
        // console.log("Language",this.state.lang);
        // console.log("Localization",this.state.localization);
    }

   
    EditMode = () => {
        this.setState({
            inEdit: true
        })
    }
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <Content>
                    <PageHeader
                        className="site-page-header"
                        onBack={this.goBacks}
                        title={this.state.localization.profile}
                        subTitle={this.state.localization.update}
                    />


                    {this.state.inEdit ? <div style={{ padding: 20, paddingTop: 0 }}>
                        <h2>
                            Change your Name
                        </h2>
                        <Form
                            name="basic"
                            labelCol={{ span: 8, }}
                            wrapperCol={{ span: 8, }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off">
                            

                            <Form.Item
                                label="Name"
                                name="name"
                               >
                                <Input defaultValue={this.state.list[0]?.name || ""} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 8, }}>
                                <Button type="primary" htmlType="submit">
                                    Update Profile
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                        :
                        <div >
                            <Row>
                                <Col span={12} offset={6}>

                                    <List
                                        // grid={{ gutter: 16, column: 4 }}
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        dataSource={this.state.list}
                                        loading={this.state.initLoading}

                                        renderItem={item => (
                                            <List.Item
                                                actions={[<Button onClick={this.EditMode}>Edit</Button>,
                                                <>
                                                    <Dropdown
                                                        overlay={(
                                                            <Menu onClick={this.setLanguage}>
                                                                <Menu.Item key="0">
                                                                    Dutch
                                                                </Menu.Item>
                                                                <Menu.Item key="1">
                                                                    English
                                                                </Menu.Item>
                                                            </Menu>
                                                        )}
                                                        trigger={['click']}>
                                                        <Button type='link'
                                                            onClick={e => e.preventDefault()}>
                                                            {this.state.lang === 'NL' ? "Kies een taal" : "Choose Language"}
                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </>,
                                                <div>
                                                    {this.state.lang === 'NL' ?"Taal":"Language"} : {this.state.lang}
                                                </div>
                                                ]}
                                            >
                                                <Skeleton avatar title={false} loading={item.loading} active>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                                        }
                                                        title={[<b>{this.state.localization.name}: </b>, <em>{item.name}</em>]}
                                                        description={[<b>{this.state.localization.email}: </b>, <em>{item.email}</em>]}
                                                    />
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </Content>
            </div>
        );
    }
}
export default Profile;