import React, { Component } from 'react';
import moment from 'moment';
import { safeDelete } from '../modals/deleteModal';
import { Table, Tag, Space,Modal,message } from 'antd';
import { getCurrentTasksAdmin,deleteTask as deleteAPI} from '../../services/AdminServices';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { getFromLocalStorage } from '../../utils/localStorageUtils';
const columns = [
    {
        title: 'Task Name',
        dataIndex: 'name',
    },
    {
        title: 'Deadline',
        dataIndex: 'date',
        render:  date => (
            <>
                {[date]?.map(tag => {
                    let notLate= moment().isBefore(tag); ;
                    let color="volcano"
                    if(notLate){
                        color="green"
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        )
    },
    {
        title: 'Language',
        dataIndex: 'language',
    },
    {
        title: 'Domain',
        dataIndex: 'author',
        key: 'author',
        render: (text, record) => (
            <Space>
                <p>{record.author}</p>
            </Space>
        )
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
            <>
                {tags?.map(tag => {
                    let color = tag.length > 5 ? 'geekblue' : 'orange';
                   
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    }, 
];


class Working extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tabData: [],
            limit:6,
            
        }
    }
    componentDidMount() {
        let reduxAvailable = this.checkReduxStore();
        if (!!reduxAvailable) {
            this.mapData(reduxAvailable);
        }
        else {
            this.loadTable();
        }

    }

    checkReduxStore = () => {
        if (!!this.props.submittedPostsRedux.length) {
            return this.props.submittedPostsRedux
        }

        else return undefined;
    }

    loadTable = () => {
        this.setState({ loading: true })

        getCurrentTasksAdmin()
            .then((res) => {
                console.log(res?.data?.data);
                this.mapData(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({ "loading": false })
            });

    }
    handleDeleteTask = (taskId, title) =>{
        const deleteTask = () => {
            return new Promise((resolve,reject) =>{
                
                let body = {"TaskId":taskId}
                //console.log("Should delete ", taskId);
                //call api
                deleteAPI(body)
                .then(res =>{
                    message.info("Task is Deleted successfully");
                    this.props.removeSubmittedPosts();
                    this.loadTable();
                })
                .catch(err=>{
                    Modal.error({"content":"Something went wrong, please try again later"});
                })
                .finally(() =>{
                    
                    resolve();
                })
            });
        };

        safeDelete(deleteTask, title);

    }
    

    getColumns = () => {
        let role = localStorage.getItem("ROLE");
        if (role === 'ADMIN') {
            return ([...columns,
            {
                title: 'Assignee',
                dataIndex: 'user'

            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Link style={{color:'#ff4d4f'}} onClick={()=> this.handleDeleteTask(record.key, record.name)}>Delete</Link>
                    </Space>
                ),
            }
            ])
        }
    }
    mapData = (mylist) => {
        let list = mylist;
        //console.log("pend Data",list);
        var exmp = {
            key: '2',
            name: 'trakstar.nl',
            language: "EN ",
            author: 'Pete',
            date: '24/9/2021',
            tags: ['performance management'],
        };
        var newData = [];
        for (var i = 0; i < list.length; i++) {
            var item = list[i];
            exmp.key = item.Id;
            exmp.name = item.Title;
            exmp.tags = item.Tags;
            exmp.author = item.Website;
            exmp.user = item.Name;
            let mo = moment(item.Deadline).format('YYYY MM DD');
            // console.log("Moment result", mo);
            exmp.date = mo.toString();
            let z = exmp;
            newData.push(z);
            exmp = {
                key: '2',
                name: 'trakstar.nl',
                language: "EN ",
                date: '24/9/2021',
                tags: ['performance management'],
                user: 'user'
            };
        }
        console.log("New Table Data", newData, list);
        this.props.setSubmittedPosts(list);
        this.setState({ tabData: newData });
        this.setState({ loading: false });
    }
    render() {
        return (
            <div>
                <div className="site-table"   >
                    <Table
                        scroll={{ x: 500 }}
                        className="generic-table"
                        columns={this.getColumns()}
                        loading={this.state.loading}
                        dataSource={this.state.tabData}
                        bordered
                        pagination={{ pageSize: this.state.limit }}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        submittedPostsRedux: state.submittedPosts || []
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setSubmittedPosts: (posts) => {
            dispatch({ type: "SET_SUBMITTED_POSTS", posts })
        },
        removeSubmittedPosts: () => {
            dispatch({ type: "REMOVE_SUBMITTED_POSTS" })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Working);

