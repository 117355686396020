export const englishText ={
    "pages":{
        "login":{
            "please":'Please login to continue',
            "enter": 'Enter User Name',
            "pass": 'Enter Password',
            "sign": 'Sign in'
        },
        "landing":{
            "available":'Available Assignments',
            "availableText":'View or edit available assignments',
            "submitted":'Submitted Assignments',
            "submittedText":'Review Submitted assignments',
            "pending":'Pending Assignments',
            "pendingText":'View Assignments that are pending',
            "writing":'Writing Guidelines',
            "writingText":'Helpful material for assignment writing',
            "completed":'Completed Assignments',
            "tasks":'My Tasks',
            "files":"Files",
            "current":'Current Tasks'

        },
        "profile":{
            "name":'Name',
            "email":'Email',
            "language":'Language',
            "profile":'Profile',
            "update":'Update Info'
        }
    }
}