import React, { Component } from "react";
// import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import {Spin} from 'antd';
import '../css/landing.css';
import Hmenu from './Hmenu';
class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedin: true,
            lang:'EN'
        }
    }
    componentDidMount() {
        let globalLang= localStorage.getItem("LANG") ||'EN';
        if(globalLang ==='EN'){
          this.setState({lang:'EN'});
        }
        else{
            this.setState({lang:'NL'});
        }
        let query = this.props.location.query || { log: false };
        // console.log("reciever query", query);
        let g = localStorage.getItem("NAME") || "not found";
        let hist = this.props.history;
        if (g !== "not found") {
            console.log("A user is found")
            setTimeout(() => {
                this.auth();
              }, 1500);
        }
        else if (g === "not found") {
            this.setState({ isLoggedin: false });
            hist.push('/login');
        }
        else if (query.log) {
            console.log("User has logged in");
            this.setState({ isLoggedin: true });
        }
        else {
            hist.push('/login');
        }
    }

    auth = () => {
        console.log("Authenticating")
        this.setState({ loggedin: true });
    }
    onFinish = (values) => {
        console.log('Success:', values);
    };
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    render() {
        return (
            <div className="landing">
                {/* {this.state.loggedin ?} */}
                {this.state.loggedin ?
                    <div>
                        <Hmenu language={this.state.lang} />
                    </div> :
                    <>
                    <Spin size="large" /></>
                    
                }


            </div>
        );
    }
}
export default Landing;

