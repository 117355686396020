import React, { Component } from 'react';
import { login } from "../services/UserServices";
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import logo from '../components/img/Logo.svg';
import { message, Button,Menu,Dropdown } from 'antd';
//languages
import { englishText } from '../constants/englishText';
import { dutchText } from '../constants/dutchText';
const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  margin-top:15px;
  min-width:250px;
  border: 2px solid #002FAA;
  text-align:center;
`;

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pass: '',
      isLoading: false,
      nextPath: '/',
      lang: '',
      localization: englishText.pages.login,
    }
  }
  componentDidMount() {
    // console.log("mounting")
    let globalLang = localStorage.getItem("LANG") || 'EN';
    if (globalLang === 'EN') {
      this.setState({ localization: englishText.pages.login });
    }
    else {
      this.setState({ localization: dutchText.pages.login });
    }



    let g = localStorage.getItem("NAME") || "x";
    // console.log("Current saved name",g);
    // console.log(this.props.location.state);
    let nextPath = this.props.location.state?.state === 'reroute' ? this.props.location.state.from.pathname : '/';
    // console.log(nextPath);
    this.setState({ nextPath: nextPath });
    let hist = this.props.history;

    if (g !== "x" && g !== "not found") {
      console.log("User is INside")
      hist.push({
        pathname: `/`,
        query: { log: true }
      });
    }

  }
  setLanguage = (e) => {
    console.log('key',e.key);
    
    let nLang='';
    if (e.key === '0' || e.key === 0) {
      nLang='NL';
      this.setState({ lang: 'NL' });
      this.setState({ localization: dutchText.pages.login });
    }
    else if(e.key=== '1' || e.key === 1) {
      nLang='EN';
      this.setState({ lang: 'EN' });
      this.setState({ localization: englishText.pages.login });
    }

    console.log("Set language here", nLang);
    localStorage.setItem("LANG", nLang);
    // console.log("Language",this.state.lang);
    // console.log("Localization",this.state.localization);
  }
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }
  handlePassChange = (e) => {
    this.setState({ pass: e.target.value });
  }
  error = () => {
    console.log('Email and password combination is incorrect');
    message.error('Email and password combination is incorrect');
  }
  authenticate = () => {

    // console.log("Checkinsfg",this.state.pass);
    let hist = this.props.history;
    this.setState({ isLoading: true });

    login({
      Email: this.state.name,
      Password: this.state.pass
    })
      .then((resp, err) => {
        //handle 206

        if (err) {
          console.log("an error");
          this.error();
          //call error message 
        }
        else if (resp) {
          if (resp.data.err) {
            console.log("response error", resp.data.err);
            this.error();
          }
          else {
            console.log("response is cool ", resp.data);
            //print response and get token and save it
            //token is in resp.data.data
            let firstLogin = false;
            console.log(resp.status);
            if (resp.status === 206) {
              firstLogin = true;
            }
            console.log("is first login", firstLogin);
            if (firstLogin) {
              //first time login 
              //re route to reset password
              console.log("Pushing to first login");
              hist.push({
                pathname: `/reset-password`,
                query: { log: true }
              });
              return;
            }
            else {
              let token = resp.data.Ticket.Token;
              let uid = resp.data.Ticket.UserId;
              localStorage.setItem("NAME", token);
              localStorage.setItem("UID", uid);
              localStorage.setItem("ROLE", resp?.data?.Role);
              //set timeout did not work 
              hist.push({
                pathname: this.state.nextPath,
                query: { log: true }
              });
            }
            console.log("after unmount");
          }
        }
        else {
          console.log("No response what so ever");
          this.error();
        }
      }).catch(err => {
        console.log("trouble loggin in ", err);
        this.error();
      })
      .finally(() => {
        this.setState({ isLoading: false });
      })

  }
  componentWillUnmount() {
    console.log('The component is going to be unmounted');
  }
  render() {
    return (
      <div className="backgy">
        <img src={logo} alt="logo" width="240px"></img>
        <h3 style={{ color: '#002FAA' }}> {this.state.localization?.please}</h3>
        <div style={{ padding: 20, paddingTop: 0 }}>
          {/* <h3>Username</h3> */}
          <Input type="email" placeholder={this.state.localization?.enter}
            onChange={this.handleNameChange} />
          {/* <h3>PASSWORD</h3> */}
          <br />
          <Input type="password" placeholder={this.state.localization?.pass}
            onChange={this.handlePassChange} />
          <br />
          
          <Button type="primary" loading={this.state.isLoading}
           onClick={this.authenticate}>
             {this.state.localization?.sign}
          </Button>
          <span
            style={{marginLeft:'12px'}}>
            <Dropdown
              overlay={(
                <Menu onClick={this.setLanguage}>
                  <Menu.Item key="0">
                    Dutch
                  </Menu.Item>
                  <Menu.Item key="1">
                    English
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}>
              <Button type='link'
                onClick={e => e.preventDefault()}>
                {this.state.lang === 'NL' ? "Kies een taal" : "Choose Language"}
                <DownOutlined />
              </Button>
            </Dropdown>
          </span>

        </div>

      </div>
    )
  }
}
export default Signin;