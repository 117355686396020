import React, { Component } from 'react';
import moment from 'moment';
import { Table, Tag,Space, Modal,Button, message,DatePicker } from 'antd';
import { getAcceptedTasksAdmin } from '../../services/AdminServices';
import { getAcceptedTasksUser } from '../../services/UserServices';
import { Link } from 'react-router-dom';
import { deleteMultipleTasks, deleteTask as deleteAPI } from '../../services/AdminServices';
import { safeDelete } from '../modals/deleteModal';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'Task Name',
        dataIndex: 'name',
        // render: (text: string) => <a>{text}</a>,
    },
    {
        title: 'Deadline',
        dataIndex: 'date',
    },
    {
        title: 'Language',
        dataIndex: 'language',
    },
    {
        title: 'Assignee',
        dataIndex: 'user',
        key:'user'

    },
    {
        title: 'Domain',
        dataIndex: 'author',
        key: 'author',
        render:(text,record) =>(
           <Space>
             <p>{record.author}</p>
           </Space>
        )
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
            <>
                {tags?.map(tag => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    }
];

class Completed extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            searchText:'',
            searchedColumn:'',
            selected: [],
            tabData:[],
            limit:6,
        }
    }
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({searchText:selectedKeys[0]});
        this.setState({searchedColumn:dataIndex});
      };
    
      handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText:''});
      };
    componentDidMount() {
        //call the data from table
        let reduxAvailable = this.checkReduxStore();

        if(!!reduxAvailable){
            this.mapData(reduxAvailable);
        }
        else{
            this.getTasks();
        } 
    }
    handleDeleteMultipleTasks = ()=>{

        const deleteTasks = () =>{
            return new Promise((resolve, reject) =>{
                let toBeDeletedTasks = this.state.selected;
                deleteMultipleTasks(toBeDeletedTasks)
                .then((res) => {
                    Modal.success({title:"Tasks deleted successfully"});
                    this.props.removeCompletedPosts();
                    this.getTasks();
                })
                .catch((err)=>{
                    Modal.error({title:"Could not delete tasks"});
                })
                .finally(() =>{
                    resolve();
                    //this.setState({deleteTaskLoading:false});
                });
            })
        }

        //this.setState({deleteTaskLoading:true});
        let taskTitles = '';
        let filteredTasks = this.state.tabData.filter((task) => this.state.selected.includes(task?.key));
        filteredTasks.forEach((post) => {
            taskTitles += '' + post?.name + ' \n ';
        });
        
        safeDelete(deleteTasks, taskTitles, true);
    }
    checkReduxStore = () =>{
        if(!!this.props.completedPostsRedux.length){
            return this.props.completedPostsRedux
        }

        else return undefined;
    }

    getTasks = () =>{
        let role = localStorage.getItem("ROLE");
        this.setState({loading:true});
        

        
        if(role === "ADMIN"){
            getAcceptedTasksAdmin()
            .then( (res)=>{
                this.mapData(res?.data?.data);
            })
            .catch((err)=>{
                console.log("Something went wrong. Please try again later",err);
                Modal.error({content:"Something went wrong. Please try again later"});
            })
            .finally(() =>{
                this.setState({loading:false});
            });
        }

        else{
            getAcceptedTasksUser()
            .then( (res)=>{
                this.mapData(res?.data?.data);

            })
            .catch((err)=>{
                console.log("We have an error getting accepted tasks",err);
                Modal.error({content:"Something went wrong. Please try again later"});
            })
            .finally(() =>{
                this.setState({loading:false});
            })
            ;   
        }
    }
    onDateChange = (date, dateString) => {
        this.setState({isLoading:true});
        console.log("Date Changed", date, dateString);
        //date is moment format
        let params={
            'startTime':dateString[0],
            'endTime':dateString[1],
        };
        getAcceptedTasksAdmin(params)
        .then( (res)=>{
            this.mapData(res?.data?.data);
        })
        .catch((err)=>{
            console.log("Something went wrong. Please try again later",err);
            Modal.error({content:"Something went wrong. Please try again later"});
        })
        .finally(() =>{
            this.setState({loading:false});
        });
    }

    handleDeleteTask = (taskId, title) =>{
        const deleteTask = () => {
            return new Promise((resolve,reject) =>{
                //this.setState({revertTaskLoading: true});
                let body = {"TaskId":taskId}
                //console.log("Should delete ", taskId);
                //call api
                deleteAPI(body)
                .then(res =>{
                    message.info("Task is Deleted successfully");
                    this.props.removeCompletedPosts();
                    this.getTasks();
                })
                .catch(err=>{
                    Modal.error({"content":"Something went wrong, please try again later"});
                })
                .finally(() =>{
                    resolve();
                })
            });
        };

        safeDelete(deleteTask, title);

    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ selected: selectedRowKeys });
    }
    mapData =(mylist)=>{
        let list=mylist;
        console.log("pend Data",list);
        var exmp = {
            key: '2',
            name: 'trakstar.nl',
            language: "EN ",
            author:'Pete',
            date: '24/9/2021',
            user:'Assignee',
            tags: ['performance management'],
        };
        var newData=[];
        for (var i=0;i<list.length;i++){
            var item= list[i];
            exmp.key=item.Id;
            exmp.user=item.Name;
            exmp.name=item.Title;
            exmp.tags=item.Tags;
            exmp.author=item.Website;
            let mo=moment(item.Deadline).format('YYYY MM DD'); 
            // console.log("Moment result",mo);
            exmp.date=mo.toString();
            let z=exmp;
            newData.push(z);
            exmp = {
                key: '2',
                name: 'trakstar.nl',
                language: "EN ",
                date: '24/9/2021',
                tags: ['performance management'],
            };
        }
        console.log("New Table Data",newData);
        this.props.setCompletedPosts(list);

        //assignIds(mylist);
        this.setState({tabData:newData});
        this.setState({loading:false});
    }
    handleTableChange = (pagination, filters, sorter) => {
        // setTableParams({
        //   pagination,
        //   filters,
        //   ...sorter,
        // });
        console.log("Paging",pagination);
        this.setState({limit:pagination.pageSize});
      };
    getColumns = () =>{
        let role = localStorage.getItem("ROLE");
        if(role === "ADMIN"){
            return(
                [...columns, 
                    {
                        title: 'Action',
                        key: 'action',
                        render: (text, record) => (
                            <Space size="middle">
                                <Link to={`/viewer/${record.key}`} rel="noopener noreferrer">Open</Link>
                                <Link style={{color:'#ff4d4f'}} onClick={() => this.handleDeleteTask(record.key,record.name)}>Delete</Link>
                            </Space>
                        ),
                    },
                ]
            )
        }

        else{
            return(
                [...columns                ]
            )
        }
    }
    render() {
        return (
            <div>
                <div className="site-table"   >
                <Space style={{marginBottom:"12px"}} direction="vertical"  >
                {localStorage.getItem("ROLE") === "ADMIN" && <>
                            <h3>Pick Time range:</h3>
                            <RangePicker
                            
                            onChange={this.onDateChange} 
                             /> </>}
                        </Space>
                    <Table
                        loading={this.state.loading}
                        scroll={{x:500}}
                        className="generic-table"
                        columns={this.getColumns()}
                        dataSource={this.state.tabData}
                        bordered
                        onChange={this.handleTableChange}
                        rowSelection={{
                            onChange: this.onSelectChange,
                        }}
                        pagination={{ pageSize: this.state.limit }}
                    />
                    <Space>
                        {localStorage.getItem("ROLE") === "ADMIN" &&
                            <Button
                                onClick={this.handleDeleteMultipleTasks}
                                type="danger"
                                disabled={this.state?.selected?.length === 0 || this.state?.selected?.length === undefined}
                            > Delete </Button>
                        }
                    </Space>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        completedPostsRedux: state.completedPosts || []
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCompletedPosts: (posts) => {
            dispatch({ type:"SET_COMPLETED_POSTS", posts });
        },
        removeCompletedPosts: (posts) => {
            dispatch({ type:"REMOVE_COMPLETED_POSTS"});
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Completed);

