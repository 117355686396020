import React, { Component } from 'react';

import { Table, Tag, Space, Modal } from 'antd';
import { getPendingTasks } from '../../services/UserServices';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const columns = [
    {
        title: 'Task Name',
        dataIndex: 'name',
        // render: (text: string) => <a>{text}</a>,
    },
    {
        title: 'Deadline',
        dataIndex: 'date',
    },
    {
        title: 'Language',
        dataIndex: 'language',
    },
    {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
        render: (text, record) => (
            <Space>
                <p>{record.name}</p>
            </Space>
        )
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
            <>
                {tags?.map(tag => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <Space size="middle">
                <Link to={`/editor/${record.key}`}>Edit</Link>
            </Space>
        ),
    },
];

class Pending extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading : false,
            limit:6
        }
    }

    mapData = (data) => {
        let ids = [];
        // console.log("The mapped data",data);
        const mappedData = data.map((row) => {
            ids.push(row);
            return {
                tags: row.Tags,
                key: row.Id,
                date: row.Deadline,
                name: row.Title,
                author: row.Name,
                language: row.Language || "_"
            }
        });
        this.setState({
            data: [...mappedData]
        });
        console.log("Action.posts should be", ids);
        this.props.setPendingPosts(data);
        //assignIds(ids);
        console.log(mappedData);
    }

    checkReduxStore = () =>{
        if(!!this.props.pendingPostsRedux.length){
            return this.props.pendingPostsRedux
        }

        else return undefined;
    }

    componentDidMount() {

        let reduxAvailable = this.checkReduxStore();
        if(!!reduxAvailable){
            this.mapData(reduxAvailable);
        }
        else{
            this.loadTable();
        }
    }


    loadTable = ()=> {
        this.setState({loading:true});
        getPendingTasks()
        .then((res) => {
                this.mapData(res.data?.data);
            })
            .catch((err) => {
                Modal.error({content: 'Could not get Pending Tasks'});
                console.log("We have an error getting pedning tasks", err);
            })
            .finally(() =>{
                this.setState({
                    loading:false
                });
            });
    }

    render() {
        return (
            <div>
                <div className="site-table">
                    <Table
                        scroll={{x:500}}
                        className="generic-table"
                        loading={this.state.loading}
                        columns={columns}
                        dataSource={this.state.data}
                        bordered
                        pagination={{ pageSize: this.state.limit }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        pendingPostsRedux: state.pendingPosts || []
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setPendingPosts: (posts) => {
            dispatch({type: "SET_PENDING_POSTS", posts})
        },
        removePendingPosts: (posts) => {
            dispatch({type: "REMOVE_PENDING_POSTS"});
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pending);


