import React, { Component } from 'react';
import { Divider, Space,Tag, Modal, Table,Select,Spin } from "antd";
import TaskModal from '../../components/TaskModal';
import { getAllMyTasks } from '../../services/UserServices';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

const { Option } = Select;

class Tasks extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Task Name',
                dataIndex: 'name',
                // render: (text: string) => <a>{text}</a>,
            },
            {
                title: 'Deadline',
                dataIndex: 'deadline'
            },
            {
                title: 'Website',
                dataIndex:'website'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status,record) => {
                    return (
                         <Tag color={this.getStatusColor(status)} key={record}>
                             {status}
                         </Tag>
                     );
                }
            },
           
        ]
        this.state = {
            //isAdmin: false,
            isLoading: false,
            data:[],
            monthCount:0,
            limit:6,
        }
    }
    componentDidMount() {
        let reduxAvailable = this.checkReduxStore();
        if(!!reduxAvailable){
            this.mapData({data:reduxAvailable});
        }
        else{
            this.loadTable();
        }
    }

    checkReduxStore = () =>{
        if(!!this.props.allPostsRedux.length){
            return this.props.allPostsRedux
        }

        else return undefined;
    }

    loadTable = ()=>{
        this.setState({isLoading:true});
        getAllMyTasks()
        .then(({data}) =>{
            this.mapData(data);
        })
        .catch((err) =>{
            Modal.error({content:"Something went wrong"});
        })
        .finally(()=>{
            this.setState({isLoading:false});
        })
    }


    mapData = ({data}) => {
        let mappedData = data.map((task) =>{
            return {
                "name":task.Title,
                "website":task.Website,
                "deadline":moment(task.Deadline).format("DD/MM/YYYY"),
                "status":task.Status,
                "key": task.Id
            }
        });
        this.props.setAllPosts(data);
        //addPostsToRedux(data);
        //console.log("Mapped Data ", mappedData);
        this.setState({data:mappedData});
    }

    getStatusColor = (status) =>{
        // console.log("STatus for color ", status);
        let color_status='black';
        switch (status) {
            case "INPROGRESS":
                color_status= "volcano";
                break;
            case "ACCEPTED":
                color_status= "green";
                break;
            case "COMPLETED":
                color_status= "gold";
                break;
            default:
                color_status= "black";
                break;
        }
        return color_status;
    }
    onMonthChange=(val) =>{
        console.log("month val",val);
        this.countTasks(val);
    }
    countTasks = (month) =>{
        var count=0;
        let tasks= this.state.data;
        console.log("current taks",tasks);
        for (var i = 0; i < tasks.length; i++) {
            var task= tasks[i];
            var st = task.status;
            /*To Do */
            // check if date is in current year
            if (st === "ACCEPTED") {
                var dl=String(task.deadline);
                //gets the month where the task deadline was
                var mon = Number(dl.substring(3,5));
                console.log("the month", mon);
                if (mon === month) {
                    count++;
                }
            }

        }
        this.setState({monthCount:count})
    }
    getColumns =()=>{
        
        let role = localStorage.getItem("ROLE");
        if (role === 'ADMIN') {
        return (
            [...this.columns,
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                           <Link to={`/viewer/${record.key}`} rel="noopener noreferrer">Open</Link>
                        </Space>
                    ),
                },
            ]);
        }
        else{
            return this.columns;
        }
    }
    render() {
        return (
            <>
            <div>
                <div    >
                <Divider orientation="left">My Tasks</Divider>
                <Space style={{marginBottom:"12px"}}>
                <span> Current Tasks Completed this month: </span>

                    
                    <Select
                    style={{marginLeft:'12px',height:"auto"}}
                        placeholder="Select a Month"
                        onChange={this.onMonthChange}
                        size={"large"}
                        allowClear
                    >
                        <Option value={1}>January</Option>
                        <Option value={2}>February</Option>
                        <Option value={3}>March</Option>
                        <Option value={4}>April</Option>
                        <Option value={5}>May</Option>
                        <Option value={6}>June</Option>
                        <Option value={7}>July</Option>
                        <Option value={8}>August</Option>
                        <Option value={9}>September</Option>
                        <Option value={10}>October</Option>
                        <Option value={11}>November</Option>
                        <Option value={12}>December</Option>
                    </Select> 
                    
                     <b style={{marginLeft:'12px'}}>{this.state.monthCount<1? <Spin />:
                     <>
                     {this.state.monthCount}</>}</b>
                     </Space>
                    <Table
                        scroll={{x:500}}
                        className="generic-table"
                        columns={this.getColumns()}
                        loading={this.state.isLoading}
                        dataSource={this.state.data}
                        bordered
                        pagination={{ pageSize: this.state.limit }}
                    />
                </div>
            </div>
            <TaskModal />
            </>

        );
    }
}


const mapStateToProps = (state) => {
    return{
        allPostsRedux:state.allPosts || []
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setAllPosts : (posts) =>{
            dispatch({type: "SET_ALL_POSTS",posts})
        },
        removeAllPosts : () => {
            dispatch({type: "REMOVE_ALL_POSTS"})
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
