import axiosInstance from './axiosConfig';
let token =localStorage.getItem("NAME");
let config = { 
    headers:{
    "Access-Control-Allow-Origin": "*",
    "refreshtoken": token,
    "authorization":token
    },
    "authorization":token, 
    "refreshtoken": token 

};

const apiUrl = 'https://www.wefrettederwolwatynom.eu';
// const apiUrl = 'http://localhost:3001';


export function createUserT(obj) {
    return axiosInstance.post(`${apiUrl}/admin/CreateUserTesting`,{'object':obj});
}

export function createUser(obj) {
    return axiosInstance.post(`${apiUrl}/admin/createuser`,obj,config);
}
export function uploadTaskFile(obj){
    return axiosInstance.post(`${apiUrl}/task/excelfilenewformat`,{ File: obj });
}
export function deleteUser(obj) {
    return axiosInstance.delete(`${apiUrl}/admin/deleteuser`,{"data":{"UserId":obj}},config);
}

export function adminLogin(obj) {
    return axiosInstance.get(`${apiUrl}/login`,{'object':obj});
}

export function adminAcceptsTask(obj) {
    return axiosInstance.put(`${apiUrl}/task/accepttask`,obj);
}


export function adminRevertsTask(obj) {
    return axiosInstance.put(`${apiUrl}/task/reverttask`,obj);
}
export function adminRevertsToUser(obj) {
    return axiosInstance.put(`${apiUrl}/task/RevertToUser`,obj);
}
export function getCompletedTasks(){
    return axiosInstance.get(`${apiUrl}/task/ViewCompletedTasks`,config);
}
export function getAcceptedTasksAdmin(obj){
    return axiosInstance.get(`${apiUrl}/task/ViewAllAcceptedTasks`,{params:obj},config);
}
export function getCurrentTasksAdmin(){
    return axiosInstance.get(`${apiUrl}/task/ViewAllCurrentTasks`,config);
}
export function deleteTask(obj){
//    console.log("Deleting task with id",obj);
    return axiosInstance.delete(`${apiUrl}/task/deletetask`,{"data":obj});
}
export function getTasksMetrics(taskType){
    return axiosInstance.get(`${apiUrl}/task/GetTasksNumber`,{ params: { "taskType": taskType } },config);
}

export function getTasksMonth(month){
    //input is optional
    return axiosInstance.get(`${apiUrl}/task/GetTasksMonth`,{ params: { "mon": month } },config);
}
export function getAllMonths(year){
    //input is optional
    return axiosInstance.get(`${apiUrl}/task/GetAllMonths`,{ params: { "year": year } },config);
}
export function deleteMultipleTasks(obj){
    return axiosInstance.delete(`${apiUrl}/admin/deletetasks`, {"data":{"TaskIds":obj}});
}








