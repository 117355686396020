const  escapeHtml = (unsafe) =>
{
    return unsafe
         .replace(/</g, "")
         .replace(/>/g, "")
         .replace(/"/g, "")
         .replace(/'/g, "");
 }

const stripHtml = (html) =>{

    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    tmp.style.whiteSpace = "pre";
    return tmp.innerText    || "";
}
 
module.exports = {
    escapeHtml: escapeHtml,
    stripHtml: stripHtml
  };