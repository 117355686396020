import React, { Component } from 'react';
import {  PageHeader,Form,Button,message,Input, Descriptions, Spin } from 'antd';
import { getUserById,updateProfile } from '../services/UserServices';

class Mgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            email: "",
            role: "",
            isLoading: true,
            uid:0,
        }
    }
    componentDidMount() {
        let name = this.props.location.state;
        this.setState({uid:name});
        // console.log("Manage name", name);
            getUserById(name.name).then((resp) => {
                // console.log("we have a user", resp.data);
                this.setState({ email: resp.data.data.Email });
                this.setState({ username: resp.data.data.Name })
                this.setState({ role: resp.data.data.Role });
                this.setState({ isLoading: false });
            }).catch((err) => {
                console.log("error getting user", err);
            })
    }
    goBacks = () => {
        let hist = this.props.history;
        hist.goBack();
    }
    onFinish = (values) => {
        console.log('Success:', values);
        values.uid=this.state.uid;
        // call api to edit user
        updateProfile(values).then( (res)=>{
            console.log("updated profile",res);
        }).catch( (err)=>{
            console.log("error updating user",err);
        })       
    };
    onFinishFailed = (errorInfo) => {
        message.error("Sorry an error occured please try again later");
       
        console.log('Failed:', errorInfo);
    };
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                {this.state.isLoading ? <><Spin /></> : <>
                    <PageHeader
                        className="site-page-header"
                        onBack={this.goBacks}
                        title={this.state.username}
                        subTitle="Manage User Page"
                    >
                        <Descriptions size="default" bordered>

                            <Descriptions.Item label="User Type">
                                <b>  {this.state.role}</b>
                            </Descriptions.Item>
                        </Descriptions>

                    </PageHeader>
                    <Form
                            name="basic"
                            labelCol={{ span: 8, }}
                            wrapperCol={{ span: 8, }}
                            style={{marginTop:'24px'}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off">
                            
                            <Form.Item
                                label="Email"
                                name="email"
                               >
                                <Input defaultValue={this.state.email} />
                            </Form.Item>

                            <Form.Item
                                label="Name"
                                name="name"
                               >
                                <Input defaultValue={this.state.username} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 8, }}>
                                <Button type="primary" htmlType="submit">
                                    Update Profile
                                </Button>
                            </Form.Item>
                        </Form>

                </>}

            </div>
        );
    }
}
export default Mgmt;