import React, { Component } from 'react';
import dayjs from 'dayjs';
import { PageHeader, Button, Descriptions, Modal, Spin,Tag , Table} from "antd";
import {editTask, submitTask} from '../services/UserServices';
import { connect } from 'react-redux';
import { stripHtml } from '../utils/escapeHTML';
const colors =["orange","magenta","red","volcano","orange","magenta","#ff4d4f","volcano"]

const columnsMissing = [
  {
    title: 'Keyword',
    dataIndex: 'keyword',
    key: 'keyword',
  },
  {
    title: 'Missing',
    dataIndex: 'missing',
    key: 'missing',
  }
]

const columnsOveruse = [
  {
    title: 'Keyword',
    dataIndex: 'keyword',
    key: 'keyword',
  },
  {
    title: 'Overused',
    dataIndex: 'overused',
    key: 'overused',
  }
]


class TaskHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tid: 0,
      task: undefined,
      le: 0,
      isLoading: true,
      disableSave: true,
      saving: false,
      submitting:false,
      tagsModalVisible:false,
      missingKeywordsList:[],

    }
  }

  static getDerivedStateFromProps(props, state){
    console.log("Props.disableSave", props.disableSave);
    console.log("state.disableSave", state.disableSave);
    if(state.disableSave !== props.disableSave && props.disableSave !== undefined)
      return {
        disableSave: props.disableSave
      };
      //add another return here 
  }
  componentDidMount() {
    //console.log("PH props", this.props);
    this.setState({ tid: this.props.taskid });
    this.setState({ task: this.props.task });

    if(this.props.task !== undefined){
      this.setState({isLoading:false});
      //console.log("Task loaded ", this.props.task);
    }
    var now = dayjs().format('DD-MM-YYYY HH:mm') ;
    this.setState({le:now});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.task !== this.props.task ) {
      //console.log("change in propss",this.props);
      this.setState({isLoading:false});
    }
 }
  goBacks = () => {

    
    let hist = this.props.history;
    hist.goBack();
  }

  success = () => {
    //console.log(this.state.task);
    let content = this.props.getValue();
    if(stripHtml(content) === "")
      content = "";
    console.log("Props.getValue()", content);
    if(!!this.state.task){
      this.setState({saving:true});
      editTask({'Title':this.state.task.Title,'TaskId':this.state.tid,'Content':content})
      .then((response)=>{
        //console.log(response);
        this.props.saveContent();
        Modal.success({
          content: 'Task Saved successfully',
        });
      })
      .catch((err) =>{
        //console.log(err);
      })
      .finally(()=>{
        this.setState({saving:false});
      })
    }
    /**/
  }

  getTagsModalContent = (missingKeywordsCountList) => {

    let content = <></>;
    if(missingKeywordsCountList.length){

      let missingKeywordsList = missingKeywordsCountList[0];
      let missingKeywordsCount = missingKeywordsCountList[1];
      let missingkeywordsMore = [];
      let missingkeywordsLess = [];
      
      missingKeywordsCount.forEach((count, index) => {
        if (count > 0){
          missingkeywordsMore.push(
            {
              keyword:missingKeywordsList[index],
              missing: count
            }
          );
        }
        else{
          missingkeywordsLess.push(
            {
              keyword:missingKeywordsList[index],
              overused: Math.abs(count)
            }
          );
        }
      });
      content = <div style={{paddingTop:'20px', paddingBottom:'20px'}}> 
      {!!missingkeywordsMore.length &&<Table
      bordered={true}
        dataSource={missingkeywordsMore} 
        columns={columnsMissing} 
      />
      }
      {!!missingkeywordsLess.length && <Table
        bordered={true}
        dataSource={missingkeywordsLess} 
        columns={columnsOveruse} 
      />
      }
      </div> ; 
      console.log(content);
    }
    return content;
  }
  handleSubmitTask = () =>{
    let taskTitle = this.props.task.Title;
    let taskContent = this.props.getValue();
    let taskId = this.state.tid;
    
    if(!!! taskTitle){
      Modal.error({"content":"Task must have a title"});
      return;
    }
    if(!!!taskContent){
      Modal.error({"content":"Task is empty"});
      return;
    }

    let toBeSubmitted = {
      "Title": taskTitle  ,
      "Content":taskContent,
      "TaskId":taskId
    }
    console.log(toBeSubmitted);

    this.setState({"submitting":true})
    submitTask(toBeSubmitted)
    .then(res=>{
      if(!!this.props.handleSuccessfulSubmit)
        this.props.handleSuccessfulSubmit();
      else
      Modal.success({
        onOk:()=>{this.goBacks()},
        content:"Task submitted successfully"});

    })
    .catch(err =>{
      if(!!err?.response?.data?.MissingKeyWords){
        let missingKeywordsList = err?.response?.data?.MissingKeyWords;
        //let missingKeywordsString = "";
        this.setState({missingKeywordsList:missingKeywordsList});
        //this.setState({tagsModalVisible:true});
    // eslint-disable-next-line 
        let modalContent = this.getTagsModalContent(missingKeywordsList);
        Modal.error({
          width:'700px',
          title:"Your task is missing some important keywords",
          content:(<>
            {modalContent}
            </>
          )
        });
      }
      else{
        Modal.error({"content":"Could not submit task"});
      }
    })
    .finally(() =>{
      this.setState({"submitting":false})
    });
 }
 checkLink =(url) =>{
   let ff=url.substring(0, 4);
   console.log("the sbstr", ff);
   if(ff === "http"){
     return url;
   }
   else{
     let ft= url.substring(0, 3);
     if(ft === "www"){
        return "https://"+url;
     }
     else{
      return "https://www."+url;
     }
   }
 }
  render() {
    return (
      <div>
        <PageHeader
          className="site-page-header"
          onBack={()=>{console.log("Why SO Careful");this.props.saveContentLocally();this.goBacks()}}
          title={this.state.task?  this.props.task.Title:"Task Title"}
          subTitle="Task info"
          extra={[
            <Button loading={this.state.saving} key="3" disabled={this.state.disableSave} onClick={this.success}>Save</Button>,
            <Button key="2" type="danger" onClick={this.goBacks}>Cancel</Button>,
            <Button loading={this.state.submitting} key="1" type="primary" onClick={this.handleSubmitTask}>
              Submit
            </Button>,
          ]}>

            {this.state.isLoading ? <Spin /> :
              <div>
                <Descriptions size="small" column={3}>
                <Descriptions.Item label="Website">
                  
              <a target="_blank" rel="noopener noreferrer" href={this.checkLink(this.props.task.Website)}>
                    {this.props.task.Website}
                  </a>
                </Descriptions.Item>
                <Descriptions.Item label="Task ID">
                  {this.state.tid}
                </Descriptions.Item>
                <Descriptions.Item label="Deadline">
                  {/* {this.state.task ? this.state.task.Deadline : ""} */}
                  {this.props.task.Deadline}
                </Descriptions.Item>
                <Descriptions.Item label="Last Edited">{this.state.le}</Descriptions.Item>
                <Descriptions.Item label="Tags">
                  {!!this.props.task.Tags && this.props.task.Tags.map((tag, index) => {
                    return (
                      <Tag color={colors[index]} key={tag}>
                          {tag.toUpperCase()}
                      </Tag>
                  );
                  }
                  )}
                  
                </Descriptions.Item>
          </Descriptions>
              </div>}

        </PageHeader>
{/*        <Modal 
        visible={this.state.tagsModalVisible}
        title="Your task is missing some important keywords"
        
        >

          <>

          {this.state.tagsModalVisible && this.getTagsModalContent()}
          </>
        </Modal>
*/}
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {

  }
}
export default connect(null, mapDispatchToProps)(TaskHeader);
