import React, { Component } from 'react';
import moment from 'moment';
import { Table, Tag, Space, Modal, message,AutoComplete,Input } from 'antd';
import { getCompletedTasksUser,getAllUsers } from '../../services/UserServices';
import {
    adminRevertsToUser,
    getCompletedTasks,
    adminRevertsTask
} from '../../services/AdminServices';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { getFromLocalStorage } from '../../utils/localStorageUtils';
import { deleteTask as deleteAPI } from '../../services/AdminServices';
import { safeDelete } from '../modals/deleteModal';
const columns = [
    {
        title: 'Task Name',
        dataIndex: 'name',
    },
    {
        title: 'Deadline',
        dataIndex: 'date',
    },
    {
        title: 'Language',
        dataIndex: 'language',
    },
    {
        title: 'Domain',
        dataIndex: 'author',
        key: 'author',
        render: (text, record) => (
            <Space>
                <p>{record.author}</p>
            </Space>
        )
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
            <>
                {tags?.map(tag => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    /* {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <Space size="middle">
                <Link onClick={()=> this.props.getPost(record.key)} to={`/viewer/${record.key}`} rel="noopener noreferrer">Open</Link>
                <a href='/' rel="noopener noreferrer">Revert</a>
                
                <a href='/' rel="noopener noreferrer">Delete</a>
            </Space>
        ),
    }, */
];

const { TextArea } = Input;

class Submitted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tabData: [],
            modalOpen:false,
            autoCompleteOptions:[],
            options:[],
            uid:3,
            tid:0,
            inputValue:"",
            limit:6,
            revertTaskLoading: false
        }
    }
    componentDidMount() {
        let reduxAvailable = this.checkReduxStore();
        if (!!reduxAvailable) {
            this.mapData(reduxAvailable);
        }
        else {
            this.loadTable();
        }
        getAllUsers()
        .then((res) => {
            console.log("users table", res.data?.data);
            
            this.mapUsers(res.data?.data);
        })
        .catch((err) =>{
            Modal.error({content:'Failed to get list of users'});
            this.setState({modalOpen:false})
            console.log("error in getting users",err);
        })
        .finally(() => {
            this.setState({loading:false});
        })

    }

    checkReduxStore = () => {
        if (!!this.props.submittedPostsRedux.length) {
            return this.props.submittedPostsRedux
        }

        else return undefined;
    }
    
    handleUserSearch = (keyword) => {
        console.log("autoCompleteoptions", this.state.autoCompleteOptions);
        let options = this.state.autoCompleteOptions.filter((item)=> item.label?.toLowerCase().includes(keyword.toLowerCase())).slice(0,3);
        console.log(options);
        this.setState({options: options});
    };
    mapUsers = (data) => {
        let mappedData = data.map((userObj)=>{
            return {'label':userObj?.Name + "(" + userObj?.Email + ")", 'value': userObj.Email, 'id':userObj.Id}});
        this.setState({autoCompleteOptions: mappedData});

    }

    loadTable = () => {
        this.setState({ loading: true })
        let role = localStorage.getItem('ROLE');
        console.log(role);
        if (role === 'USER') {
            getCompletedTasksUser()
                .then((res) => {
                    console.log(res?.data?.data);
                    this.mapData(res?.data?.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({ "loading": false });
                });
        }

        else if (role === 'ADMIN') {

            getCompletedTasks()
                .then((res) => {
                    console.log(res?.data?.data);
                    this.mapData(res?.data?.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({ "loading": false })
                });
        }
    }


    handleDeleteTask = (taskId, title) => {
        const deleteTask = () => {
            return new Promise((resolve, reject) => {
                let body = { "TaskId": taskId }
                //console.log("Should delete ", taskId);
                //call api
                deleteAPI(body)
                    .then(res => {
                        message.info("Task is Deleted successfully");
                        this.props.removeSubmittedPosts();
                        this.loadTable();
                    })
                    .catch(err => {
                        Modal.error({ "content": "Something went wrong, please try again later" });
                    })
                    .finally(() => {
                        resolve();
                    })
            });
        };

        safeDelete(deleteTask, title);

    }
    handleRevertToUser(taskId, title) {
        console.log("convert");
        this.setState({tid:taskId});
        this.setState({modalOpen:true});

    }
    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };
    handleOk = () =>{
        this.setState({modalOpen:false});
        return new Promise((resolve, reject) => {
            let returnUser = this.state.options[0].id;
            console.log("return msg",this.state.inputValue);
            let taskId=this.state.tid;
            let body = { 
                "TaskId": taskId,
                "uid": returnUser,
                "reason":this.state.inputValue,
            }
            adminRevertsToUser(body)
                .then(res => {
                    message.info("Task is Reverted successfully");
                    this.props.removeSubmittedPosts();
                    this.loadTable();
                })
                .catch(err => {
                    Modal.error({ "content": "Something went wrong, please try again later" });
                })
                .finally(() => {
                    this.setState({ revertTaskLoading: false });
                    resolve();
                })
        });
    }
    handleCancel = () =>{
        this.setState({modalOpen:false});
    }
    handleRevertTask(taskId, title) {
        const revertTask = () => {
            return new Promise((resolve, reject) => {
                this.setState({ revertTaskLoading: true });
                let body = { "TaskId": taskId }
                adminRevertsTask(body)
                    .then(res => {
                        message.info("Task is Reverted successfully");
                        this.props.removeSubmittedPosts();
                        this.loadTable();
                    })
                    .catch(err => {
                        Modal.error({ "content": "Something went wrong, please try again later" });
                    })
                    .finally(() => {
                        this.setState({ revertTaskLoading: false });
                        resolve();
                    })
            });

        };

        Modal.confirm({
            //okButtonProps:{loading:this.state.revertTaskLoading},
            title: 'Are you sure you want to revert this task?',
            content: `${title}`,
            onOk: () => {
                return revertTask()
            },
            //onOk() {revertTask},
            onCancel() {
                console.log("Cancel");
            },

        });

    }
    getColumns = () => {
        let role = localStorage.getItem("ROLE");
        if (role === 'ADMIN') {
            return ([...columns,
            {
                title: 'Assignee',
                dataIndex: 'user'

            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/viewer/${record.key}`} rel="noopener noreferrer" style={{ color: '#2f22b2' }}>Open</Link>
                        <Link onClick={() => this.handleRevertToUser(record.key, record.name)}>Revert To User</Link>
                        <Link onClick={() => this.handleRevertTask(record.key, record.name)}>Revert</Link>
                        <Link style={{ color: '#ff4d4f' }} onClick={() => this.handleDeleteTask(record.key, record.name)}>Delete</Link>
                    </Space>
                ),
            }
            ])
        }
        else {
            return (
                [...columns,
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            <Link to={`/viewer/${record.key}`} rel="noopener noreferrer">Open</Link>
                        </Space>
                    ),
                }
                ]
            )
        }
    }
    mapData = (mylist) => {
        let list = mylist;
        //console.log("pend Data",list);
        var exmp = {
            key: '2',
            name: 'trakstar.nl',
            language: "EN ",
            author: 'Pete',
            date: '24/9/2021',
            tags: ['performance management'],
        };
        var newData = [];
        for (var i = 0; i < list.length; i++) {
            var item = list[i];
            exmp.key = item.Id;
            exmp.name = item.Title;
            exmp.tags = item.Tags;
            exmp.author = item.Website;
            exmp.user = item.Name;
            let mo = moment(item.Deadline).format('YYYY MM DD');
            // console.log("Moment result", mo);
            exmp.date = mo.toString();
            let z = exmp;
            newData.push(z);
            exmp = {
                key: '2',
                name: 'trakstar.nl',
                language: "EN ",
                date: '24/9/2021',
                tags: ['performance management'],
                user: 'user'
            };
        }
        console.log("New Table Data", newData, list);
        this.props.setSubmittedPosts(list);
        this.setState({ tabData: newData });
        this.setState({ loading: false });
    }
    render() {
        return (
            <div>
                <div className="site-table"   >
                    <Table
                        scroll={{ x: 500 }}
                        className="generic-table"
                        columns={this.getColumns()}
                        loading={this.state.loading}
                        dataSource={this.state.tabData}
                        bordered
                        pagination={{ pageSize: this.state.limit }}
                    />
                    <Modal 
                    title="Revert To user" 
                    visible={this.state.modalOpen} 
                    onOk={this.handleOk} onCancel={this.handleCancel}>
                        <p>Enter User Name:</p>
                        <AutoComplete
                            options={this.state.options}
                            allowClear ={true}
                            placeholder="Enter User Name"
                            onSearch={this.handleUserSearch}
                            defaultActiveFirstOption={true}
                            style={{width:"240px"}}
                            >
                            </AutoComplete>
                        <p>Enter Reason:</p>
                        <TextArea rows={3}
                        onChange={this.handleInputChange}
                        placeholder="Type Your message here"/>
                        
                    </Modal>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        submittedPostsRedux: state.submittedPosts || []
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setSubmittedPosts: (posts) => {
            dispatch({ type: "SET_SUBMITTED_POSTS", posts })
        },
        removeSubmittedPosts: () => {
            dispatch({ type: "REMOVE_SUBMITTED_POSTS" })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Submitted);

