const { Modal } = require("antd");

const safeDelete = (deleteCallBack, title,multiple = false) => {
    let warningMessage = multiple? 'Are you sure you want to delete these tasks?' : 'Are you sure you want to delete this task?' ;
    Modal.confirm({
        title: warningMessage,
        content: `${title}`,
        onOk : ()=>{
            return deleteCallBack()
        },
        //onOk() {revertTask},
        onCancel() {
          console.log("Cancel");
        },
        style:{whiteSpace:"pre-line"}        
      });
}

module.exports = {
    safeDelete: safeDelete
}