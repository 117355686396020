import React, { Component } from 'react';

import { PageHeader, Steps } from 'antd';

const { Step } = Steps;
class Guidelines extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    goBacks = () => {
        let hist = this.props.history;
        hist.goBack();
    }
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <PageHeader
                    className="site-page-header"
                    onBack={this.goBacks}
                    title="Handleiding schrijvers"
                    subTitle="Writing Guidelines"
                />
                
                <div style={{paddingLeft:64,paddingRight:64}}>

                <Steps direction="vertical" current={4}>
                    <Step title="1" description="Je teksten moeten altijd uniek zijn. Soms moet je 
                    meerdere teksten over een onderwerp schrijven maar hou ze altijd uniek. Als teksten of 
                    delen daarvan gekopieerd worden, houdt de samenwerking gelijk op en wordt al je werk 
                    van die maand verwijderd. " />
                    <Step title="2" description="Je teksten / recensies mogen heel oppervlakkig geschreven 
                    worden, probeer in ieder geval in 15 minuten een tekst af te hebben. " />
                    <Step title="3" description="Je kunt altijd deze opbouw aanhouden: je had een 
                    probleem, je ging online op zoek naar een oplossing, je kwam op de betreffende site 
                    terecht, je probleem kon worden opgelost, je bent heel tevreden." />
                    
                    <Step title="4" description="Laat op tijd weten wanneer het je niet lukt om je teksten 
                    te schrijven, dan kunnen ze waarschijnlijk nog doorgeschoven worden naar andere 
                    schrijvers." />
                </Steps>
                </div>


            </div>
        );
    }
}

export default Guidelines;