import axios from 'axios';

const apiUrl = 'https://tekst-entikker.herokuapp.com';
// const apiUrl = 'http://localhost:3001';

const axiosInstance = axios.create({
    baseURL:`${apiUrl}`,
    headers:{
        Accepted: 'application/json',
        'Content-Type': 'application/json'
    },
});
axiosInstance.interceptors.request.use(
    (config) =>{
        const token = localStorage.getItem("NAME");
        if (token){
            config.headers.authorization =token;
            config.headers.refreshtoken = token;
        }
        return config;
    },
    (error) => Promise.reject(error),
);
export default axiosInstance;


