import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// import { LastLocationProvider } from "react-router-last-location";
// import ForgotPassword from "views/Authentication/ForgotPassword";
// import Signin from "views/Authentication/Signin";
// import Signup from "views/Authentication/Signup";
import ResetPassword from "../views/Authentication/ResetPassword";
// import EmailConfirmed from "views/Authentication/EmailConfirmed";
// import ProtectedRoutes from "Router/ProtectedRoutes";
// import Inputs from "layouts/Inputs";
// import Output from "layouts/Output";
import Landing from '../Pages/Landing';
import Four from "../views/htp/Four04";
import Info from "../views/msgs/Info";
import Home from '../Pages/Home';
import Signin from '../Pages/Signin';
import MyEditor from '../Pages/MyEditor';
import Profile from '../Pages/Profile';
import Users from '../Pages/Users';
import Mgmt from '../Pages/Mgmt';
import CPassword from '../Pages/CPassword';
import TaskViewer from "../Pages/taskViewer";
import Guidelines from '../Pages/Guidelines';
import MyHeader from "../components/MyHeader";
import UserTasks from "../views/admin/UserTasks";

const ProtectedComponents = () => {
  return (
    <>

      <MyHeader />
      <ProtectedRoute path="/editor/:id" component={MyEditor} />
      <ProtectedRoute path="/editor" component={MyEditor} />
      
      <ProtectedRoute path="/users/tasks/:id" component={UserTasks} />
      <ProtectedRoute path="/users/manage/:id" component={Mgmt} />
      <ProtectedRoute path="/users" component={Users} />
      <ProtectedRoute path="/changePassword" component={CPassword} />
      <ProtectedRoute path="/profile/:id" component={Profile} />
      <ProtectedRoute path="/viewer/:id" component={TaskViewer} />
      <ProtectedRoute path="/viewer" component={TaskViewer} />
      <ProtectedRoute path="/guidelines" component={Guidelines} />
    </>
  )
}
const Router = () => {
  return (
    // <LastLocationProvider>
    <>
      <Switch>
        <Route path="/login" name="Login" component={Signin} />
        <Route exact path="/" component={Landing} />
        <Route
        exact
          path="/reset-password"
          component={ResetPassword}
        />
        <Route path="/logout" component={Info} />

        <ProtectedRoute path="/available" component={Home} />
        <ProtectedRoute path="/history" component={Home} />
        <ProtectedRoute path="/complete" component={Home} />
        <ProtectedRoute path="/submitted" component={Home} />
        <ProtectedRoute path="/working" component={Home} />
        <ProtectedRoute path="/pending" component={Home} />
        <ProtectedRoute path="/MyTasks" component={Home} />
        <ProtectedRoute path="/files" component={Home} />
        <ProtectedComponents />

        {/* <Route path="/profile/:id" component ={Profile} />
      <Route path="/profile/:id/change" component ={CPassword} /> */}
        {/* <Route
          path="/reset-password/:email/:passwordResetToken"
          component={ResetPassword}
        /> */}
        <Route path="*" component={Four} />

        {/* <Route path="/signin" component={Signin} />
        <Route path="/register" component={Signup} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route
          path="/verify-email/:email/:emailVerificationToken"
          component={EmailConfirmed}
        />
        <Route
          path="/reset-password/:email/:passwordResetToken"
          component={ResetPassword}
        />
        <Route path="/public/task/:id/" component={Inputs} /> */}
        {/* <ProtectedRoutes /> */}
      </Switch>
    </>
    // </LastLocationProvider>
  );
};


function ProtectedRoute({ location, component, ...rest }) {
  //commented until they are functional

  //let auth = useAut();
  let token = localStorage.getItem("NAME");
  let role = localStorage.getItem("ROLE");
  //let firstLogin = false;
  let isAuthenticated = (role === 'USER' || role === 'ADMIN') && token;
  let canPass = isAuthenticated;
  // console.log("can pass ", canPass, location);

  return (
    <Route
    exact
      {...rest}
      component={canPass ? component : () => <Redirect
        to={{
          pathname: "/login",
          state: { from: location, state: "reroute" }
        }}
      />
      }

    />
  );
}
export default Router;
