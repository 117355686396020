export const dutchText ={
    "pages":{
        "login":{
            "please":'Gelieve in te loggen om verder te gaan',
            "enter": 'E‑mailadres',
            "pass": 'Wachtwoord',
            "sign": 'Inloggen'
        },
        "landing":{
            "available":'Beschikbaar',
            "current":'Uitgezet',
            "availableText":'Bekijk of schrijf beschikbare opdrachten',
            "submitted":'Ingeleverd',
            "submittedText":'Bekijk ingeleverde opdrachten',
            "pending":'Tikken',
            "pendingText":'Bekijk de nog te schrijven teksten',
            "writing":'Schrijfhandleiding',
            "writingText":'Tekstvoorwaarden en tips',
            "completed":'Goedgekeurd',
            "tasks":'Mijn opdrachten',
            "files":"Bestanden"
        },
        "profile":{
            "name":'Naam',
            "email":'Email',
            "language":'Taal',
            "profile":'Gebruikers',
            "update":'Update Informatie'
        }
    }
}