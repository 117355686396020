import React, { Component, } from 'react';
import { BarChart, Bar, ResponsiveContainer,XAxis,YAxis,Tooltip,Label } from 'recharts';

import { Button, Card, Col, Row, Statistic, Progress,Select,Spin} from 'antd';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowUpOutlined } from '@ant-design/icons';
import {  getTasksMetrics, getTasksMonth,getAllMonths } from '../../services/AdminServices';

const { Option } = Select;
const barData = [
    {
        name: 'January',
        TotalTasks: 4000,
        pv: 2400,
    },
    {
        name: 'February',
        TotalTasks: 3000,
        pv: 1398,
    },
    {
        name: 'March',
        TotalTasks: 2000,
        pv: 9800,
    },
    {
        name: 'June',
        TotalTasks: 3490,
        pv: 4300,
    },
];

const Wr = styled.dt`
  width: 35%;
  float: left;
`;
const Ts = styled.dd`
  width: 60%;
  float: left;
  padding-left: 20%;
`;
const DetailedList = styled.dl`
    width: 100%;
  overflow: hidden;
  /* background: #ff0; */
  padding: 0;
  margin: 0;

`;
class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completeCount:0,
            availableCount:0,
            allCount:100,
            usersNo:13,
            curPercentage:0,
            workCount:0,
            ready:false,
            topList:[],
            graphData:barData,
            graphResp:[]
        }
    }
    onMonthChange=(val) =>{
        console.log("month val",val);
        // no need to set state just send number to API call
        this.countTasks(val);
    }
    countTasks = (month)=>{
         //getting users counts
         getTasksMonth(month).then((resp)=>{
            // console.log("rec resp",resp);
            this.setState({topList:resp.data.countedTasks});

        }).catch((err)=>{
            console.log("error getting Users Tasks", err);
        });

    }
    mapGraph=()=>{
        const months=[
            "Jan ","Feb ","Mar ","Apr ",
            "May","Jun ","Jul ","Aug ",
            "Sep ","Oct ","Nov ","Dec "
                    ];
        
        var newBar=[];
        getAllMonths().then((resp)=>{
            console.log("graph resp", resp.data.countedTasks);
            let respBar=resp.data.countedTasks;
            respBar.sort(function(a,b) {
                return a._id.month - b._id.month
            });
            for(var mon of respBar){
                var barSample={
                    name: 'January',
                    TotalTasks: 100,
                    pv: 24,
                };
                barSample.name= months[mon._id.month-1] + "" +mon._id.year;
                barSample.pv=mon.count;
                barSample.TotalTasks=mon.count;
                
                newBar.push(barSample);
                // console.log("Month",barSample);
            }
            // console.log("new data",newBar);
        }).catch((err)=>{
            console.log("error getting Graph data", err);
        })
        this.setState({graphResp:newBar});
    }
    componentDidMount(){
        //getting graph data
        this.mapGraph();
       
       
        //getting all tasks count
        getTasksMetrics().then((resp)=>{
            // console.log("resp recieved",resp.data);
            
            this.setState({allCount:resp.data.countedTasks.allCount});
            this.setState({completeCount:resp.data.countedTasks.finishCount});
            this.setState({availableCount:resp.data.countedTasks.pendCount});
            let per = Math.round( (resp.data.countedTasks.progCount / resp.data.countedTasks.allCount ) * 100);
            this.setState({curPercentage:per});
        })
        .catch((err)=>{
            console.log("error getting All Tasks", err);
        });
        
    }
    // componentDidUpdate(prevProps){
        

    // }
    render() {
        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="Completed Tasks"
                        //  extra={<FileOutlined />}
                        >
                            <Statistic value={this.state.completeCount} />
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card title="Available Tasks">
                            <Statistic value={this.state.availableCount} precision={2}
                                suffix={<Button style={{ marginLeft: "25%" }} type="primary">
                                    <Link to='/available'>    View</Link>
                                </Button>} />
                        </Card>

                    </Col>
                    <Col span={4}>
                        <Card title="Active Users">
                            <Statistic value={this.state.usersNo} suffix={<ArrowUpOutlined />} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Currently working on tasks"
                            extra={<Link to="/working">More</Link>}>
                            <Progress type="circle" percent={this.state.curPercentage} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Card title="Top Writers Last Month" extra={
                              <Select
                              style={{marginLeft:'12px',height:"auto"}}
                                  placeholder="Select a Month"
                                  onChange={this.onMonthChange}
                                  size={"large"}
                                  allowClear
                              >
                                  <Option value={1}>January</Option>
                                  <Option value={2}>February</Option>
                                  <Option value={3}>March</Option>
                                  <Option value={4}>April</Option>
                                  <Option value={5}>May</Option>
                                  <Option value={6}>June</Option>
                                  <Option value={7}>July</Option>
                                  <Option value={8}>August</Option>
                                  <Option value={9}>September</Option>
                                  <Option value={10}>October</Option>
                                  <Option value={11}>November</Option>
                                  <Option value={12}>December</Option>
                              </Select>
                        }>
                            
                            {this.state.topList.length>0?   <DetailedList>
                                {this.state.topList.map(item=>{
                                    return(
                                        <>
                                        <Wr>
                                            {item._id.Name}
                                        </Wr>
                                        <Ts>
                                            {item.count}
                                        </Ts>
                                        </>
                                    )
                            })}
                            </DetailedList>:<><Spin size={"large"}></Spin></>}
                            
                        </Card>

                    </Col>
                    <Col span={12}>
                        {/* ToDO check if graph resp is empty */}
                        {this.state.graphResp.length > 0 ? <>
                            <ResponsiveContainer width="100%" height="100%">

                                <BarChart width={180} height={150} 
                                data={this.state.graphResp} 
                                margin={{ top: 36, right: 24, left: 24, bottom: 36 }}>
                                    <XAxis dataKey="name" >

                                        <Label value="Total Tasks this year" 
                                        offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis dataKey="pv" >

                                    </YAxis>
                                    <Tooltip />
                                    {/* <Legend verticalAlign="top" height={36}/> */}
                                    <Bar dataKey="TotalTasks" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>

                        </> :
                            <>
                                <Spin size={"large"}></Spin>
                            </>}

                    </Col>
                </Row>
            </div>
        );
    }
}

export default Analytics;