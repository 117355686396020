import React, { Component } from 'react';
import { Layout, PageHeader, Table,DatePicker,Space } from "antd";
import dayjs from 'dayjs';
import { getUserById,  getAcceptedTasksUserWithId } from '../../services/UserServices';

const { Footer, Content } = Layout;
const { RangePicker } = DatePicker;

class UserTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: 0,
            username: "",
            email: "",
            role: "",
            isLoading: false,
            limit:8,
            data: [],
        }
        this.columns = [
            {
                title: 'Task Name',
                dataIndex: 'taskname',

            },
            {
                title: 'Deadline',
                dataIndex: 'deadline',
                sorter: {
                    compare:(a, b) => a.deadline - b.deadline
                }
                // sorter: (a, b) => ('' + a.deadline).localeCompare(b.deadline),
            },
            {
                title: 'Language',
                dataIndex: 'language',
                filters: [
                    {
                        text: 'English',
                        value: 'EN',
                    },
                    {
                        text: 'Dutch',
                        value: 'NL',
                    },
                ],
                onFilter: (value, record) => record.language.startsWith(value),
                filterSearch: true,
                width: '40%',
            },
        ];

    }
    componentDidMount() {

        let name = this.props.location.state;
        this.setState({ uid: name });
        
        getUserById(name.name).then((resp) => {
            // console.log("we have a user", resp.data);
            this.setState({ email: resp.data.data.Email });
            this.setState({ username: resp.data.data.Name })
            this.setState({ role: resp.data.data.Role });
        }).catch((err) => {
            console.log("error getting user", err);
        })
        this.loadTable(name);
    }
    loadTable = (uid) => {
        console.log("das id", uid);
        this.setState({ isLoading: true });
        let params={'uid':uid.name};
        getAcceptedTasksUserWithId(params)
            .then((res) => {
                console.log(res?.data?.data);
                this.mapData(res?.data?.data);
            })
            .catch((err) => {
                console.log("error in getting tasks");
                console.log(err);
                this.setState({data:[]});
            })
            .finally(() => {
                this.setState({ "loading": false });
            });
    }
    mapData = (data) => {
        console.log("Data to be mapped",data);
        let mappedData = data.map((task) => {
            return {
                "taskname": task.Title,
                "deadline": dayjs(task.EndDate).format("DD/MM/YYYY"),
                "language": task.Language || "English",
            }
        });
        this.setState({ data: mappedData });
        
        this.setState({ isLoading: false });
    }
    onChange = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
        this.setState({limit:pagination.pageSize});
    };
    onDateChange = (date, dateString) => {
        this.setState({isLoading:true});
        console.log("Date Changed", date, dateString);
        //date is moment format
        let params={
            'uid':this.state.uid.name,
            'startTime':dateString[0],
            'endTime':dateString[1],
        };
        getAcceptedTasksUserWithId(params)
            .then((res) => {
                console.log(res?.data?.data);
                this.mapData(res?.data?.data);
            })
            .catch((err) => {
                console.log("error in getting tasks");
                console.log(err);
                this.setState({data:[]});
            })
            .finally(() => {
                this.setState({ "loading": false });
            });

    }
    goBacks = () => {
        let hist = this.props.history;
        hist.goBack();
    }
    render() {
        return (
            <div className="mid">
                <Layout>
                    <PageHeader
                        className="site-page-header"
                        onBack={this.goBacks}
                        title={this.state.username}
                        subTitle="User's Tasks "
                    />
                    <Content className="cont">
                        <Space style={{marginBottom:"12px"}} direction="vertical"  >
                            <h3>Pick Time range:</h3>
                            <RangePicker
                            onChange={this.onDateChange} 
                            
                             />
                        </Space>
                        <Table
                        columns={this.columns}
                        dataSource={this.state.data}
                        loading={this.state.isLoading}
                        pagination={{pageSize:this.state.limit}}
                        onChange={this.onChange} />
                    </Content>
                    <Footer >Created with Love by : Out of the Box solutions</Footer>
                </Layout>
            </div>
        );
    }
}

export default UserTasks;