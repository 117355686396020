import React, { Component } from 'react';
import { Button, Col, Descriptions, Layout,message,Modal,PageHeader, Row, Spin, Tag} from 'antd';
import moment from 'moment';
import {CopyOutlined} from '@ant-design/icons';

import { connect } from 'react-redux';
import { adminAcceptsTask, adminRevertsTask } from '../services/AdminServices';
import { getTaskById } from '../services/UserServices';
const colors =["orange","magenta","red","volcano","orange","magenta","red","volcano"]
const { Content } = Layout;
class TaskViewer extends Component{
    constructor(props){
        super(props);
        this.state={
            username:"",
            viewTask:undefined,
            taskId:undefined,
            acceptingTaskLoading:false,
            revertTaskLoading:false,
            showAcceptAndRevert:false,
            isGettingTask:false,
            isAdmin:false
        }
        this.myRef = React.createRef();

    }


    setPost = (post) =>{
        //this.setState({viewTask:{...post,"ExternalPortal":""}});
        this.setState({viewTask:post});
        if(post?.Status === "ACCEPTED")
        this.setState({showAcceptAndRevert:false});
    else
        this.setState({showAcceptAndRevert:true});
    }
    componentDidMount(){
        let tid= this.props.match.params.id;
        this.setState({taskId:tid});
        
        let role = localStorage.getItem('ROLE');
        if (role ==='ADMIN'){
            this.setState({isAdmin:true});
        }
        console.log("Deciding where to go ", this.props);
        let viewingPostRedux;
        
        if(!!this.props?.viewingPosts)
            viewingPostRedux = this.props?.viewingPosts[tid] || undefined;

        if(!!viewingPostRedux){
            console.log("From Redux ", viewingPostRedux);
            this.setPost(viewingPostRedux);
        }
        else{
            console.log("From Back ");
            this.getTaskContent(tid);   
        }

    }

    getTaskContent = (Id) =>{
        this.setState({isGettingTask: true});
        getTaskById(Id)
        .then((res) =>{
            let data = res?.data;
            console.log(data?.data);
            this.setPost(data?.data);
            this.props.setViewingPosts(data?.data);
        })
        .catch((err) =>{
            message.error("Could not find task");
            this.goBack();
        })
        .finally(() =>{
            console.log("view task ", this.state.viewTask)
            this.setState({isGettingTask:false});
        });
    }
    copyContent = async ()=>{
        const elem = this.myRef.current.innerHTML;
        const blob = new Blob([elem], { type: "text/html" });
        const richTextInput = new window.ClipboardItem({ "text/html": blob });
        await navigator.clipboard.write([richTextInput]);
      }

    handleAcceptTask = () => {
        this.setState({acceptingTaskLoading: true});
        let body = {"TaskId":this.props.match.params.id}
        adminAcceptsTask(body)
        .then(res =>{
            message.info("Task is accepted successfully");
            this.props.clearStore();
            this.goBacks();
        })
        .catch(err=>{
            Modal.error({"content":"Something went wrong, please try again later"});
        })
        .finally(() =>{
            this.setState({acceptingTaskLoading:false});
        })
    }
    handleReverTask = () =>{
        this.setState({revertTaskLoading: true});
        let body = {"TaskId":this.props.match.params.id}
        adminRevertsTask(body)
        .then(res =>{
            message.info("Task is Reverted successfully");
            this.props.clearStore();
            this.goBacks();
        })
        .catch(err=>{
            Modal.error({"content":"Something went wrong, please try again later"});
        })
        .finally(() =>{
            this.setState({revertTaskLoading:false});
        })
    }
    createMarkup() {
        return {__html: this.state.viewTask.Content};
    }
    goBacks= ()=>{
        let hist= this.props.history;
        hist.goBack();
    }

    getExtraButtons = ()=>{
        let role = localStorage.getItem("ROLE");
        if(role === "ADMIN" && this.state.showAcceptAndRevert){
            return(
                [
                    <Button loading={this.state.revertTaskLoading} key="2" onClick={this.handleReverTask}>Revert</Button>,
                    <Button loading={this.state.acceptingTaskLoading} key="1" type="primary" onClick={this.handleAcceptTask}>
                      Accept
                    </Button>
                ]
            );
        }
        return [];
    }
    render(){
        return(
            <div >
            {this.state.isGettingTask &&  <Layout style={{position:'relative',paddingTop:'150px', height:'100vh', width:'100vw'}}><Spin/></Layout>}
            {!!this?.state?.viewTask && !this.state.isGettingTask && <>
            <Layout style={{minHeight:'100vh'}} >
                <PageHeader
                    className="site-page-header"
                    onBack={this.goBacks}
                    title={this.state.viewTask?.Title}
                    extra={this.getExtraButtons()}
                >
                    
                    <Descriptions size="small" column={4}>
                        <Descriptions.Item label="Website"> {this.state.viewTask?.Website}</Descriptions.Item>
                        <Descriptions.Item label="Task ID">
                          {this.state.taskId}
                        </Descriptions.Item>
                        <Descriptions.Item label="Deadline">
                          {/* {this.state.task ? this.state.task.Deadline : ""} */}
                          {moment(this.state.viewTask?.Deadline).format("DD/MM/YYYY")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Edited At">{moment(this.state.viewTask?.EndDate).format("DD/MM/YYYY, h:mm:ss a")}</Descriptions.Item>
                        <Descriptions.Item label="Last Edited By">{this.state.viewTask?.Name}</Descriptions.Item>
                        <Descriptions.Item label="Tags">
                          {!!this.state.viewTask?.Tags && this.state.viewTask?.Tags.map((tag, index) => {
                            return (
                              <Tag color={colors[index]} key={tag}>
                                  {tag.toUpperCase()}
                              </Tag>
                          );
                          }
                          )}

                        </Descriptions.Item>
                       {this.state?.isAdmin &&  <Descriptions.Item label="External Portal Link">
                       <a href={`${this.state.viewTask?.ExternalPortal}`}rel="noreferrer" target="_blank">
                              {this.state.viewTask?.ExternalPortal}
                          </a>
                        </Descriptions.Item>}
                        {this.state?.isAdmin && <Descriptions.Item label="Copy Content">
                            <Button type="primary" style={{marginLeft:'72px'}} onClick={this.copyContent} icon={<CopyOutlined />}>
                                Copy
                            </Button>
                        </Descriptions.Item>
                        } 
                        
                        
                    </Descriptions>
                </PageHeader>
            <Content>
                
                <Row justify="space-around" style={{}}>
                    <Col span={20} style={{backgroundColor:'white',padding:20,borderRadius:'5px'}}>            
                        <div ref={this.myRef} dangerouslySetInnerHTML={this.createMarkup()} />
                    </Col>
                   
                </Row>
            </Content>
            </Layout>
            </>
            }
            </div>
        );
    }
}

const mapStateToProps= (state) =>{
    return{
        viewingPosts: state.viewingPosts,

    }
}
const mapDispatchToProps= (dispatch) =>{
    return{
        setViewingPosts: (post) => {
            dispatch({type: "SET_VIEWING_POSTS", post})
        },
        clearStore: ()=>{
            dispatch({type: "CLEAR_STORE"});
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TaskViewer);

