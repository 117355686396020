import React, { Component } from 'react';
import { Card, Typography, Row } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyHeader from '../components/MyHeader';
//languages
import { englishText } from '../constants/englishText';
import { dutchText } from '../constants/dutchText';

const StyledLink = styled(Link)`
  color: Blue;
  text-decoration: none;
  margin: 5px;
  position: relative;
`;
const { Paragraph } = Typography;

const Content = ({ children, extraContent }) => (
    <Row>
        <div className="image">{extraContent}</div>
        <div style={{ flex: 1 }}>{children}</div>
    </Row>
);

class Hmenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedin: true,
            lang:'EN',
            localization:englishText.pages.landing
        }
    }
    componentDidMount(){
        // console.log("mounting")
          let globalLang= localStorage.getItem("LANG") ||'EN';
          if(globalLang ==='EN'){
            this.setState({localization:englishText.pages.landing});
          }
          else{
            this.setState({localization:dutchText.pages.landing});
          }
          if(this.props.language ==='EN'){
            this.setState({localization:englishText.pages.landing});
            this.setState({lang:'EN'});
          }
          else if(this.props.language ==='NL'){
            this.setState({localization:dutchText.pages.landing});
            this.setState({lang:'NL'});
          }
    }
    componentDidUpdate(prevProps) {
        console.log("did it update?x")
        // Typical usage (don't forget to compare props):
        if (this.props.language !== prevProps.language) {
          this.setState({ lang: this.props.language }, this.componentDidMount);
        }
      }
    render() {
        return (
            <div>
                <MyHeader/>
                <Card
                    title={this.state.localization?.available}
                    className="site-page-header"
                    style={{ width: '500px'   }}
                    key="1"
                    extra={[
                        <StyledLink to='/available'>See More</StyledLink>,
                    ]}
                >
                    <Content extraContent={
                        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                            alt="content" width="50%" />}>
                        <Paragraph>
                            {this.state.localization?.availableText}
                        </Paragraph>
                    </Content>
                </Card>

                <Card
                    title={this.state.localization?.submitted}
                    className="site-page-header"
                    style={{ width: '500px'   }}
                    key="2"
                    extra={[
                        <StyledLink to='/submitted'>See More</StyledLink>,
                    ]}
                >
                    <Content extraContent={
                        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                            alt="content" width="50%" />}>
                        <Paragraph>
                        {this.state.localization?.submittedText}
                        </Paragraph>
                    </Content>
                </Card>

                <Card
                    title={this.state.localization?.pending}
                    className="site-page-header"
                    style={{ width: '500px'   }}
                    key="3"
                    extra={[
                        <StyledLink to='/pending'>See More</StyledLink>,
                    ]}
                >
                    <Content extraContent={
                        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                            alt="content" width="50%" />}>
                        <Paragraph>
                        {this.state.localization?.pendingText}
                        </Paragraph>
                    </Content>
                </Card>

                <Card
                    title={this.state.localization?.writing}
                    className="site-page-header"
                    key="4"
                    style={{ width: '500px'   }}
                    extra={[
                        <StyledLink to='/guidelines'>See More</StyledLink>,
                    ]}
                >
                    <Content extraContent={
                        <img src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
                            alt="content" width="50%" />}>
                        <Paragraph>
                        {this.state.localization?.writingText}
                        </Paragraph>
                    </Content>
                </Card>


            </div>
        );
    }
}

export default Hmenu;