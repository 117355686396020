const initState = {
  posts: [],
  myPosts: [],
  editingPosts: {},
  viewingPosts: {},
  userid: 0,
  viewdPost:{},

  pendingPosts:[],
  availablePosts:[],
  submittedPosts:[],
  completedPosts:[],
  allPosts:[]
};

const postReducer = (state = initState, action) => {
  let newstate = {
    ...state,
  };
  let statetoBeUpdated = state;

  
  switch (action.type) {


    case "SET_PENDING_POSTS":
      return {...state, pendingPosts:[...action.posts]}

    case "REMOVE_PENDING_POSTS":
      statetoBeUpdated = state;
      delete statetoBeUpdated['pendingPosts'];
      //  delete state.availablePosts;
      return statetoBeUpdated;

    case "SET_AVAILABLE_POSTS":
      //console.log("setting available task", action.posts);
      return {...state, availablePosts:action.posts}

    case "REMOVE_AVAILABLE_POSTS":
      //console.log("removing", state.availablePosts);
      statetoBeUpdated = state;
      delete statetoBeUpdated['availablePosts'];
      //  delete state.availablePosts;
      return statetoBeUpdated;

    case "SET_SUBMITTED_POSTS":
      return {...state, submittedPosts:[...action.posts]}

    case "REMOVE_SUBMITTED_POSTS":
      statetoBeUpdated = state;
      console.log("State to be updated", statetoBeUpdated);
      delete statetoBeUpdated['submittedPosts'];
      console.log("State to be updated after deletion", statetoBeUpdated);
      //  delete state.availablePosts;
      return statetoBeUpdated;
      

    case "SET_COMPLETED_POSTS":
      return {...state, completedPosts:[...action.posts]}

    case "REMOVE_COMPLETED_POSTS":
      statetoBeUpdated = state;
      delete statetoBeUpdated['completedPosts'];
      //  delete state.availablePosts;
      return statetoBeUpdated;


    case "SET_ALL_POSTS":
      return {...state, allPosts:[...action.posts]}

    case "REMOVE_ALL_POSTS":
      statetoBeUpdated = state;
      delete statetoBeUpdated['allPosts'];
      //  delete state.availablePosts;
      return statetoBeUpdated;


    case "SET_EDITING_POST":
      console.log("Setting editing post to ", action.post);
      return { ...state,
        editingPosts: {...state.editingPosts,[action.post.Id]:action.post}
      };

    case "SET_VIEWING_POSTS":
      console.log("Setting viewing post to ", action.post);
      return { ...state,
        viewingPosts: {...state.viewingPosts,[action.post.Id]:action.post}
      };
    
    
    case "REMOVE_VIEWING_POST":
      console.log("Removing viewing Post ", action.Id);
      let oldViewingPosts = state.viewingPosts;
      delete oldViewingPosts[action.Id];
      console.log(oldViewingPosts);
      return{ ...state,
        viewingPosts:oldViewingPosts
      }

    case "REMOVE_EDITING_POST":
      console.log("Removing Editing Post ", action.Id);
      let oldEditingPosts = state.editingPosts;
      delete oldEditingPosts[action.Id];
      console.log(oldEditingPosts);
      return{ ...state,
        editingPosts:oldEditingPosts
      }  
    case "DELETE_POST":
      const newPost = state.posts.filter((post) => post.Id !== action.Id);
      return {
        posts: newPost,
      };
    case "CLEAR_STORE":
      return{};
    default:
      return newstate;
  }
};

export default postReducer;