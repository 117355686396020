import React, { Component } from 'react';
import PropTypes from "prop-types";
// import RichTextEditor from 'react-rte';
import SNRichTextEditor from '../components/summerNote';
// import { Col, Row, DatePicker, Space, Layout, Tag} from "antd";

import { Col, Row,  Layout, message, Spin } from "antd";
import '../css/editor.css';
// import LabelWrap from '../components/LabelWrap';
import TaskHeader from '../components/TaskHeader';
// import {getTask} from '../services/UserServices';
import {connect} from 'react-redux';
import { Prompt } from 'react-router';
// import { getTask } from '../services/UserServices';
import { getTaskById } from '../services/UserServices';
// import { ReactSummernote } from 'react-summernote';
// import WEditor from '../components/WEditor';
const { Content } = Layout;



class MyEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGettingTask: false,
            data:undefined,
            value: '',
            post:undefined,
            textUpdated: undefined,
         //   showExitPrompt: false
        }
    }

    static propTypes = {
        onChange: PropTypes.func
    };

    handleSubmissionSuccess = () =>{
        console.log("Text Updated");
        this.setState({textUpdated:true});
        message.info("Task submitted successfully");
        this.props.clearStore();
        this.goBack();
    }

    geTaskConent = (taskId) => {
        this.setState({isGettingTask:true});
        getTaskById(taskId)
        .then((res) =>{
            let data = res?.data;
            this.setPost(data);  
        })
        .catch((err) =>{
            message.error("Could not find task");
            this.goBack();
            //Modal.error({content:"Could not find this task"});
        })
        .finally(() =>{
            this.setState({isGettingTask:false});
        });
    }

    setPost = ({data}) => {
        console.log("getTAsk byId : ", data);
        console.log("text updated? ", this.state.textUpdated);
        this.setState({post:data});
    }
    
    componentDidMount() {
        let tid= this.props.match.params.id;


        console.log("Deciding where to go ", this.props);
        let editingPostRedux;
        
        if(!!this.props?.editingPosts)
            editingPostRedux = this.props?.editingPosts[tid] || undefined;

        else
            editingPostRedux = undefined

        if(!!editingPostRedux){
            console.log("From redux");
            this.setState({textUpdated:false});
            this.setPost({data:editingPostRedux});        
        }
        
        else{
            console.log("From back");
            //this.setState({textUpdated:true});
            this.geTaskConent(tid);
        }

        /*if(!!myPosts?.length){
            // eslint-disable-next-line 
            myPost = myPosts.filter(post => post.Id == tid)[0];
            console.log("Redux ", myPosts);
            console.log("redux my post ",myPost);
            this.setState({post:{...myPost}});
        }*/
        /*else{
            myPost = getFromLocalStorage("POST");
            this.setState({post:{...myPost}});
        }*/
        // let cont = RichTextEditor.createValueFromString( myPost.Content, 'html');
        //console.log(this.state.value);
        //console.log("Type of cont is ", typeof cont, cont);
        //this.setState({value:{...cont}});
        /*window.onbeforeunload = (event) => {

            saveInLocalStorage(myPost,"POST");
            event.returnValue = 'Unsaved changes will be lost, are you sure you want to Close the page?'
            return 'hilkhb';
        };*/
    }

    onDateChange = (date, dateString) => {
    }

    onChange = (value) => {

        console.log("Changing value ", value);
        this.setState({ value });
        if(this.state.textUpdated === undefined)
            this.setState({textUpdated: true});
        else if(this.state.textUpdated)
            this.setState({ textUpdated: false });

    };
    goBack = () => {
        let hist = this.props.history;
        hist.goBack();
    }

    getContent = () =>{
        return this.state.value || "";
    }

    saveContent = () =>{
        console.log("Updating text");
        this.setState({textUpdated: true});
        this.props.removeEditingPost(this.state.post?.Id);
        /*let myPost = this.state.post;
        myPost.Content = this.state.value;
        window.onbeforeunload = (event) => {
            saveInLocalStorage(myPost,"POST");
            event.returnValue = 'Unsaved changes will be lost, are you sure you want to Close the page?'
            return 'hilkhb';
        };*/
    }

    saveContentLocally = () => {
        let modifiedPost = {...this.state?.post, "Content":this.state.value};
        console.log("Text updated ", this.state.textUpdated);
        if(!this.state.textUpdated && this.state.textUpdated!==undefined)
            this.props.setEditingPost(modifiedPost);
    }

    /*componentWillUnmount(){
        window.onbeforeunload = null;
    }*/
    render() {

        return (
        <>
            <Prompt
            when={!this.state.textUpdated}
            message="Unsaved changes will be lost. Are you sure you want to leave this page without saving?"
            />
            <div  >
            
                <Layout>
                    {this.state.isGettingTask &&  <Layout style={{position:'relative',paddingTop:'150px', height:'100vh', width:'100vw'}}><Spin/></Layout>}

                    {!!this.state.post && !this.state.isGettingTask && <TaskHeader 
                    handleSuccessfulSubmit={this.handleSubmissionSuccess}
                    disableSave={this.state.textUpdated} 
                    getValue={this.getContent}
                    history={this.props.history} 
                    task={this.state.post} 
                    taskid={this.props.match.params.id} 
                    saveContent={this.saveContent}
                    saveContentLocally={this.saveContentLocally}
                    />}
                    {!this.state.isGettingTask && <Content>
                        <div className="mainview">

                            <Row>
                                {/* <Col span={10} offset={2}>
                                    <Space direction="vertical">
                                        
                                        <LabelWrap label="Keywords" content={
                                            <div>
                                                <Tag color="orange">Tag 1</Tag>
                                                <Tag color="magenta">Tag 2</Tag>
                                                <Tag color="red">Tag 3</Tag>
                                                <Tag color="volcano">Tag 4</Tag>
                                            </div>
                                        } />
                                        <LabelWrap label="Language" content={
                                            <b> English</b>
                                        } />
                                        <LabelWrap label="Start date" content={<DatePicker style={styles.wid} onChange={this.onDateChange} />} />
                                        <LabelWrap label="End date" content={<DatePicker style={styles.wid} onChange={this.onDateChange} />} />

                                    </Space>

                                </Col>
                                <Col span={12}>
                                    <WEditor />
                                </Col> */}
                                <Col span={22} offset={1}>
                                    {!!this.state.post && <SNRichTextEditor value={this.state.post?.Content}  onChange={this.onChange}/>}
                                </Col>
                            </Row>
                        </div>

                    </Content>
                    }
                </Layout>
            </div>
            </>
        );
    }
}
const mapStateToProps= (state) =>{
    return{
        posts:state.posts,
        myPosts:state.myPosts,
        editingPosts: state.editingPosts
    }
}
const mapDispatchToProps= (dispatch) =>{
    return{
        setEditingPost: (post)=>{
            dispatch({type:"SET_EDITING_POST", post});
        },
        removeEditingPost: (Id) =>{
            dispatch({type:"REMOVE_EDITING_POST",Id});
        },
        clearStore: ()=>{
            dispatch({type: "CLEAR_STORE"});
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(MyEditor);