import axiosInstance from './axiosConfig';
let token =localStorage.getItem("NAME");
let config = { 
    headers:{
    "Access-Control-Allow-Origin": "*",
    "refreshtoken": token,
    "authorization":token
    },
    "authorization":token, 
    "refreshtoken": token 

};
const fakeDataUrl = `https://randomuser.me/api/`;

const apiUrl = 'https://www.wefrettederwolwatynom.eu';
// const apiUrl = 'http://164.92.159.174';
// const apiUrl = 'http://localhost:3001';

export function login(obj){
    console.log("signing be ",obj);
    return axiosInstance.post(`${apiUrl}/login`, obj)
}
export function logout(){
    // console.log("signing out");
    localStorage.setItem("NAME", "not found");
    return axiosInstance.delete(`${apiUrl}/logout`,config);
}
export function changePassword(obj){
    console.log("password is getting changed");
    return axiosInstance.post(`${apiUrl}/user/changepassword`,obj,config)
}
export function getAllUsers(){
    console.log("Getting all users");
    return axiosInstance.get(`${apiUrl}/user/getallusers`,config);
}

export function createTask(obj) {
    console.log("Recieved obj",obj)
    return axiosInstance.post(`${apiUrl}/task/CreateTask`,obj,config);
}    
export function updateProfile(obj){
    console.log("updating profile",obj);
    return axiosInstance.post(`${apiUrl}/user/UpdateUser`,obj,config)
}

export function resetPasswordFirstLogin(obj){
    return axiosInstance.post(`${apiUrl}/user/resetpassword`, obj)
}

export function getTasks() {
    // console.log("My token",config);
    return axiosInstance.get(`${apiUrl}/task/ViewPendingTasks`,config);
}

export function getPendingTasks() {
    return axiosInstance.get(`${apiUrl}/task/viewmytasks`,config);
}

export function getCompletedTasksUser(){ 
    return axiosInstance.get(`${apiUrl}/task/viewusercompletedtasks`,config);
}

export function getAcceptedTasksUser(){
    return axiosInstance.get(`${apiUrl}/task/ViewUserAcceptedTasks`,config);
}
export function getAcceptedTasksUserWithId(obj){
    return axiosInstance.get(`${apiUrl}/task/GetUserTasks`,{params:obj},config);
}
export function getAllMyTasks(){
    return axiosInstance.get(`${apiUrl}/task/viewallusertasks`);
}

export function assignTask(obj){
    return axiosInstance.put(`${apiUrl}/task/claimmultipletasks`,{'TasksID':obj});
}

export function editTask(obj){
    return axiosInstance.put(`${apiUrl}/task/SaveTask`,{'Title':obj.Title,'TaskId':obj.TaskId,'Content':obj.Content});
}


export function submitTask(obj){
    return axiosInstance.put(`${apiUrl}/task/submittask`,obj,config);
}

export function getTaskById(taskId){
    return axiosInstance.get(`${apiUrl}/task/Gettask`, {params:{'TaskId':taskId}});
}
export function getUserById(UserId){
    return axiosInstance.get(`${apiUrl}/user/getuser`, {params:{'UserId':UserId}});

}


//my stuff
// export function submitTask(obj){
//     return axiosInstance.put(`${apiUrl}/task/submittask`,obj);
// }

//test stuff
export function getList(){
    console.log("Getting List");
    return axiosInstance.get(`${fakeDataUrl}`);
}
