import React, { Component } from 'react';
import { Row, Table, Tag, Space, Button, Col, message, Modal } from "antd";
import moment from 'moment';
import { getTasks } from '../../services/UserServices';
import {connect} from "react-redux";
import { assignTask } from '../../services/UserServices';
import { safeDelete } from '../modals/deleteModal';
import { deleteMultipleTasks, deleteTask as deleteAPI} from '../../services/AdminServices';
import { Link } from 'react-router-dom';
class Available extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Task Name',
                dataIndex: 'name',
                // render: (text: string) => <a>{text}</a>,
            },
            {
                title: 'Deadline',
                dataIndex: 'date',
            },
            {
                title: 'Language',
                dataIndex: 'language',
            },
        
            {
                title: 'Tags',
                key: 'tags',
                dataIndex: 'tags',
                render: tags => (
                    <>
                        {tags && tags.map(tag => {
                            let color = tag.length > 5 ? 'geekblue' : 'green';
                            if (tag === 'loser') {
                                color = 'volcano';
                            }
                            return (
                                <Tag color={color} key={tag}>
                                    {tag.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            }
        ];
        let role = localStorage.getItem("ROLE");
        if(role === "ADMIN")
            this.columns.push(
                {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                        <Space size="middle">
                            {//<StyledLink to={`/editor/${record.key}`}  >Edit</StyledLink>
                            }
                            
                            <Link style={{color:'#ff4d4f'}}
                             onClick={()=>{this.handleDeleteTask(record.key, record.name)}}>
                                Delete</Link>
                        </Space>
                    ),
                }
    )
        this.state = {
            isMobile: false,
            offset: 14,
            tableData: [],
            loading: false,
            selected: [],
            pendingPosts: [],
            claimingTaskLoading:false,
            deleteTaskLoading: false,
            limit:6
        }
    }

    handleDeleteTask = (taskId, title) =>{
        const deleteTask = () => {
            return new Promise((resolve,reject) =>{
                //this.setState({revertTaskLoading: true});
                let body = {"TaskId":taskId}
                console.log("Should delete ", taskId);
                //call api
                deleteAPI(body)
                .then(res =>{
                    message.info("Task is Deleted successfully");
                    this.props.removeAvailablePosts();
                    this.getAvailableTasks();
                })
                .catch(err=>{
                    Modal.error({"content":"Something went wrong, please try again later"});
                })
                .finally(() =>{
                    //this.setState({revertTaskLoading:false});
                    resolve();
                })
            });
        };

        safeDelete(deleteTask, title);

    }

    handleDeleteMultipleTasks = ()=>{

        const deleteTasks = () =>{
            return new Promise((resolve, reject) =>{
                let toBeDeletedTasks = this.state.selected;
                deleteMultipleTasks(toBeDeletedTasks)
                .then((res) => {
                    Modal.success({title:"Tasks deleted successfully"});
                    this.props.removeAvailablePosts();
                    this.getAvailableTasks();

                })
                .catch((err)=>{
                    Modal.error({title:"Could not delete tasks"});
                })
                .finally(() =>{
                    resolve();
                    //this.setState({deleteTaskLoading:false});
                });
            })
        }

        //this.setState({deleteTaskLoading:true});
        let taskTitles = '';
        let filteredTasks = this.state.tableData.filter((task) => this.state.selected.includes(task?.key));
        filteredTasks.forEach((post) => {
            taskTitles += '' + post?.name + ' \n ';
        });
        
        safeDelete(deleteTasks, taskTitles, true);
    }

    checkReduxStore = () =>{
        if(!!this.props.availablePostsRedux.length){
            return this.props.availablePostsRedux
        }

        else return undefined;
    }
    componentDidMount() {
        //call the data from table
        let reduxAvailable = this.checkReduxStore();
        if(!!reduxAvailable){
            this.mapData({data:reduxAvailable});
        }
        else{
            this.getAvailableTasks();
        }
        //this.getAvailableTasks();
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    getAvailableTasks = ()=>{
        this.setState({loading:true});
        getTasks().then((res) => {
            //the below commented line shows the data of the table
            console.log(res.data);
            //call function to map response to objs
            this.mapData(res.data);
            
        })
        .catch((err) => {
            Modal.error({content:"Something went wrong, please try again later"})
        })
        .finally(() => {
            this.setState({loading:false});

        })
        ;

    }

    mapData = (mylist) => {
        let list = mylist.data;
        console.log("setting posts state to ", list);
        this.setState({
            pendingPosts:list
        })
        console.log("old Data", list);
        var exmp = {
            key: '2',
            name: 'trakstar.nl',
            language: "EN ",
            date: '24/9/2021',
            tags: ['performance management'],
        };
        var newData = [];
        for (var i = 0; i < list.length; i++) {
            var item = list[i];
            exmp.key = item.Id;
            exmp.name = item.Title;
            exmp.tags = item.Tags;
            let mo = moment(item.Deadline).format('YYYY MM DD');
            // console.log("Moment result",mo);
            exmp.date = mo.toString();
            let z = exmp;
            newData.push(z);
            exmp = {
                key: '2',
                name: 'trakstar.nl',
                language: "EN ",
                date: '24/9/2021',
                tags: ['performance management'],
            };
        }
        console.log("New Table Data", newData);
        this.setState({ tableData: newData });
        this.setState({ loading: false });
        this.props.setAvailablePosts(list);
    }
    assignTasks = () => {
        this.setState({claimingTaskLoading:true});
        // let lengthOfTasks = this.state.selected.length;
        assignTask(this.state.selected)
        .then(res =>{
            console.log(res);
            let response = res?.data;
            let responseMessage ="";
            //console.log("Response ", response, !!responseMessage?.claimedTasks);
            if(!!response?.claimedTasks){
                response.claimedTasks.forEach(element => {
                    console.log(element);
                    responseMessage += " \n " +  element?.Title + " \n ";
                });

            }
            this.props.removeAvailablePosts();
            //console.log("If I am here then problemos");
            Modal.success({
                title:  response.message,
                content: responseMessage,
                style:{whiteSpace:"pre-line"}
              });
            this.getAvailableTasks();
        })
        .catch(({response}) => {
            console.log(response.data);
            let errorMessage = "";
            switch (response?.data?.message) {
                case "3T020":
                    errorMessage = "You assigned tasks are at max capacity";
                    break;
            
                default:
                    errorMessage = "Something went wrong";
                    break;
            }
            Modal.error({title: errorMessage});
        })
        .finally(() => {        
            this.setState({claimingTaskLoading:false});
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ selected: selectedRowKeys });
    }
    /*confirming =(e,obj)=> {
        console.log("confirming",obj);
        deleteTask(obj).then((res) => {
            //the below commented line shows the data of the table
            console.log("delete response",res.data);
            this.handleDelete(obj);
        })
            .catch((err) => {
                console.log("We have an error deleting the task", err);
            });
        message.success('Task Deleted');
      }*/
      
/*      canceling =(e)=> {
        console.log(e);
        message.error('Task NOT Deleted');
      }*/
    
    /*handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
          tableData: dataSource.filter((item) => item.key !== key),
        });
      };
     */ 
    resize = () => {
        let w = window.innerWidth;
        if (w < 900) {
            this.setState({ offset: 0 });
        }
        else {
            this.setState({ offset: 14 });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);

    }
    render() {
        return (
            <div className="admin-available-table">
                <Table
                    scroll={{x:500}}
                    className="generic-table"
                    rowSelection={{
                        onChange: this.onSelectChange,
                    }}
                    columns={this.columns}
                    dataSource={this.state.tableData}
                    bordered
                    loading={this.state.loading}
                    pagination={{ pageSize: 6 }}
                />
                <Row>
                    <Col span={3} offset={1}>
                        <Space>
                            <Button disabled={this.state?.selected?.length === 0 || this.state?.selected?.length === undefined} loading={this.state.claimingTaskLoading} 
                            onClick={this.assignTasks}> {localStorage.getItem('LANG')==='NL'? "Accepteer":"Claim" }  </Button>
                            {localStorage.getItem("ROLE") === "ADMIN" && <Button 
                                //loading={this.state.deleteTaskLoading} 
                                onClick={this.handleDeleteMultipleTasks} 
                                type="danger"
                                disabled={this.state?.selected?.length === 0 || this.state?.selected?.length === undefined}
                                > Delete </Button>
                            }
                        </Space>
                    </Col>
                </Row>
            </div>

        );
    }
}
const mapStateToProps= (state) =>{
    return{
        availablePostsRedux:state.availablePosts || []
    }
}
const mapDispatchToProps= (dispatch) =>{
    return{
        setAvailablePosts: (posts) =>{
            dispatch({ type:"SET_AVAILABLE_POSTS", posts });
        },
        removeAvailablePosts: () =>{
            dispatch({ type:"REMOVE_AVAILABLE_POSTS"});

        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Available);

