import React, { Component } from "react";
import { Layout, Menu,Space,Spin } from "antd";
import '../css/home.css';
// import Maine from './Maine';
import Completed from '../views/admin/Completed';
import Submitted from '../views/admin/Submitted';
import Pending from '../views/admin/Pending';
import Available from '../views/admin/Available';
import Analytics from '../views/admin/Analytics';
import Working from "../views/admin/Working";
import Tasks from '../views/admin/Tasks';
import FileUpload from '../views/admin/FileUpload';
import MyHeader from '../components/MyHeader';
import {FileDoneOutlined,FileSearchOutlined,FileSyncOutlined,
 FileOutlined, DiffOutlined, ProfileOutlined,FileWordOutlined,PieChartOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { englishText } from '../constants/englishText';
import { dutchText } from '../constants/dutchText';

const { Footer, Sider, Content } = Layout;
// const { SubMenu } = Menu;
class Home extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selected: "",
      collapsed: false,
      isLoading: false,
      lang:'EN',
      localization:englishText.pages.landing,
      admin:(localStorage.getItem("ROLE") === 'ADMIN')|| false,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.location !== prevProps.location){
      console.log("I update in ", this.props.location.pathname);
      let paths=this.props.location.pathname;
      let ps= (paths.substring(1) ).toUpperCase();
      this.setState({selected:ps});
    }

  }

  componentDidMount() {
    let globalLang= localStorage.getItem("LANG") ||'EN';
          if(globalLang ==='EN'){
            this.setState({localization:englishText.pages.landing});
          }
          else{
            this.setState({localization:dutchText.pages.landing});
          }
    console.log("I am in ", this.props.location.pathname);
    let paths=this.props.location.pathname;
    let ps= (paths.substring(1) ).toUpperCase();
    this.setState({selected:ps});
  }
  componentWillUnmount(){
    //remove the show
  }

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  toggleCategories() {
    var tab = this.state.selected;
    
    console.log("here is the ",tab);
    var c = tab.charAt(0);

    switch (c) {
      case 'A' :
        return (
          <Available />
        )
      case 'H' :
        return (
          <Analytics />
        )
      
      case 'C': 
      return (
          <Completed />
        )
  
      case 'S':
        return (
          <Submitted />
        )
      
      case 'M':
        return(
          <>
          <Tasks admin={this.state.admin}/>
          </>
        );
      case 'W':
        return(
          <>
            <Working/>
          </>
        )
      case 'P':
        // console.log("Tasks pending");
        return(
          <Pending />
          // <Tasks admin={this.state.admin}/>
        );
      case 'F':
        // console.log("Files page");
        return(
          <>
          {this.state.admin && <FileUpload /> }
          
          </>
        );
    
      default:
        return(
          <></>
        );
    }

    

  }

  handleClick = e => {
    // console.log('Vertical menu clicked ', e);
    this.setState({
      selected: e.key,
    });
  }
  onCollapse = collapsed => {
    // console.log(collapsed);
    this.setState({ collapsed });
  };
  render() {
    return (
      <div>
        <Layout>
          <Sider className="sider-dark" collapsible width={220}
          collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
            <Menu selectedKeys={this.state.selected} theme="dark"  mode="inline" 
            /*</Sider>onClick={this.handleClick}*/>
              
              <Menu.Item  key="PENDING" icon={<FileSyncOutlined />} >
                <Link style={{all:"unset"}} to="/pending"> {this.state.localization?.pending}</Link>
              </Menu.Item>
              
              <Menu.Item  key="SUBMITTED" icon={<DiffOutlined />}>
                <Link style={{all:"unset"}} to="/submitted"> {this.state.localization?.submitted} </Link>
              </Menu.Item>
              
              <Menu.Item key="COMPLETE" icon={<FileDoneOutlined />}>
                <Link style={{all:"unset"}} to="/complete"> {this.state.localization.completed}</Link>
              </Menu.Item>

              <Menu.Item key="AVAILABLE" icon={<FileSearchOutlined />}>
                <Link style={{all:"unset"}} to="/available">{this.state.localization?.available}</Link>
              </Menu.Item>
              
              {this.state.admin  &&  <Menu.Item key="HISTORY" icon={<PieChartOutlined />} >
                <Link style={{all:"unset"}} to="/history">Analytics</Link>
              </Menu.Item>}
              
              {this.state.admin  && <Menu.Item key="WORKING" icon={<FileWordOutlined />} >
                <Link style={{all:"unset"}} to="/working">{this.state.localization?.current}</Link>
              </Menu.Item>}
              {/* {this.state.admin  && } */}
              <Menu.Item key="MYTASKS" icon={<ProfileOutlined />}>
               <Link style={{all:"unset"}} to="/MyTasks"> {this.state.localization.tasks}</Link>
              </Menu.Item>
              {this.state.admin && <Menu.Item  key="FILES" icon={<FileOutlined />}>
                <Link style={{all:"unset"}} to="/files">{this.state.localization.files}</Link>
              </Menu.Item>}
              
            </Menu>
          </Sider>
          <Layout>
            <MyHeader />
            <Content className="site-layout"
              // style={{ padding: "0 50px", marginTop: 64 }}>
              style={{ marginTop: 24 }}
              >
                {this.state.isLoading ? 
                <div className="centeralized">
                  <Space>
                    <Spin size="large" />
                  </Space>
                </div> : 
                  <div>{this.toggleCategories()}</div>

                }
            </Content>
            <Footer  className="footer-dark">
              Created with Love by : Out of the Box solutions
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default Home;
