import { FileDoneOutlined, LoadingOutlined, PieChartOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Form, Input, Button, Row, Col, Steps} from 'antd';
import Text from 'antd/lib/typography/Text';

import React, { Component } from 'react';
import { login, resetPasswordFirstLogin } from '../../services/UserServices';
const {Step} = Steps;


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordsUnequal : false,
            isbuttonLoading:false,
            loginButtonVisible:false,
            isPasswordCreated:'wait',
            isVerifying:'wait',
            //don't forget
            isDone:'wait',
            errorMessageVisible:'none',
            failedLogin:'none',
            credentials:{}
        }
    }

    loginUser = ({email,password})=>{
        this.setState({isVerifying:'process'});
        this.setState({isbuttonLoading:true});
        login({
            Email: email,
            Password: password
          })
          .then((res)=>{
            this.setState({isVerifying:'finish'});
            this.setState({isDone:'finish'});
            this.setState({failedLogin:'none'});
            let token = res.data.Ticket.Token;
            localStorage.setItem("NAME", token);
            localStorage.setItem("ROLE", res?.data?.Role);
          })
          .catch((err)=>{
            this.setState({isVerifying:'wait'});
            this.setState({failedLogin:''});
          })
          .finally(()=>{
              this.setState({isbuttonLoading:false});
          })
          
    }

    onSubmit = ({NewPassword, repeatPassword, Email, OldPassword}) =>{
        console.log(repeatPassword, NewPassword)
        if(NewPassword !== repeatPassword ){
            this.setState({isPasswordsUnequal: true,errorMessageVisible:''});
            return;
        }
        else{
            this.setState({isPasswordCreated:'process'});
            this.setState({isPasswordsUnequal: false,errorMessageVisible:'none'});
            this.setState({isbuttonLoading:true});
            resetPasswordFirstLogin( {
                "Email":Email,
                "OldPassword":OldPassword,
                "NewPassword":NewPassword
            })
            .then((res) => {
                this.setState({isPasswordCreated:'finish'});
                this.setState({loginButtonVisible:true});
                this.setState({credentials:{"email":Email,"password":NewPassword}})
                try{
                    this.loginUser({"email":Email,"password":NewPassword});
                }
                catch(err){
                    console.log(err);
                }
            })
            .catch((err) =>{
                this.setState({isPasswordCreated:'wait'});
                console.log(err);
            })
            .finally(()=>{
                //this.setState({isbuttonLoading:false});
            })
        }
    }

    onSubmitFailed = () => {

    }

    render() {
        return (


            <Layout
                style={{height:'100vh'}}
            >

                
            <Row
                align="middle"
                style={{marginTop:'100px', marginBottom:'100px'}}
            >
                {this.state.isDone === 'finish' &&<Col  span={19} push={3} type="flex" align="middle"  style={{marginBottom:'200px'}}>
                    <Button size="large"  onClick={()=>this.props.history.push('/')} type="primary" icon={<PieChartOutlined />}>
                        Start Working
                    </Button>    
                </Col>
                }
                {this.state.isDone !== 'finish' &&<>
                <Col span={18} push={3}>
                    <Form
                        name="resetPassword"
                        labelCol={{span:8}}
                        wrapperCol={{span:16}}
                        initialValues = {{remember:true}}
                        onFinish = {this.onSubmit}
                        onFinishFailed = {this.onSubmitFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="Email"
                            rules = {[{required:true, message: 'Email field is required'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Old Password"
                            name="OldPassword"
                            rules={[{required:true, message:'Old Password is required'}]}   
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="NewPassword"
                            rules={[{required:true, message:'New Password is required'}]}   
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            label="Repeat Password"
                            name="repeatPassword"
                            rules={[{required:true, message:'Password is required'}]}   
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item wrapperCol={{offset:8, span: 16 }} style={{display:this.state.errorMessageVisible}}>
                            <Text  type="danger" >
                                Repeated password field is not the same as new password    
                            </Text>    
                        </Form.Item>
                        <Form.Item wrapperCol={{offset:8, span: 16 }} style={{display:this.state.failedLogin}}>
                            <Text  type="danger" >
                                Failed to verify, please try logging in again.
                                If failed again, please contact your admin    
                            </Text>    
                        </Form.Item>
                        <Form.Item wrapperCol={{offset:5, span: 5 }} style={{marginTop:"20px"}}>
                            {!this.state.loginButtonVisible  && <Button loading={this.state.isbuttonLoading} type="primary" htmlType="submit">
                                Create Password
                            </Button>
                            }
                            {this.state.loginButtonVisible && <Button loading={this.state.isbuttonLoading} type="primary" onClick={()=>this.loginUser(this.state.credentials)}>
                                Login
                            </Button>
                            }
                        </Form.Item>
                    </Form>
                </Col>
                </>
                }
                <Col span={18} push={3}>
                    <Steps>
                      <Step status={this.state.isPasswordCreated} title="Create Your Password" icon={this.state.isPasswordCreated !== 'process'?<UserOutlined />:<LoadingOutlined/>} />
                      <Step status={this.state.isVerifying} title="Verification" icon={this.state.isVerifying !== 'process'?<SolutionOutlined />:<LoadingOutlined/>} />
                      <Step status={this.state.isDone} title="Done" icon={this.state.isDone !== 'process'?<FileDoneOutlined />:<LoadingOutlined/>} />
                    </Steps>
                </Col>
                
            </Row>
            </Layout>
        );
    }
}

export default ResetPassword;