import React, { Component } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { createUser } from '../services/AdminServices';
class FormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:false,
            setVisible:false,
            password:'',
            icovisible:false
        }
    }
    createAUser = (values) => {
            console.log('Received values of form: ', values);
            let role="USER";
            if(values.modifier === "public"){
                role="ADMIN"
            }
            let userObj={
                "Email":(values.email).toLowerCase(),
                "Role":role,
                "Password":values.password,
                "Name":values.name
                }
            createUser(userObj).then((resp)=>{
                console.log("User created successfully",resp);
            }).catch( (err)=>{
                console.log("errror creating user",err);
            });
            //add create user
            setTimeout(() => {
                this.setState({visible:false})
              }, 2000);
        }
    flipState =()=>{
        let fs= this.state.visible;
        this.setState({visible:!fs});
    }
    generatePass = () =>{
        var pass = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 
                    'abcdefghijklmnopqrstuvwxyz0123456789@#$';
              
            for (var i = 1; i <= 8; i++) {
                var char = Math.floor(Math.random()
                            * str.length + 1);
                  
                pass += str.charAt(char)
            }
            console.log("geenrated",pass);
            this.setState({password:pass});
    }
    render() {
        return (
            <div>
                <Modal
                    visible={this.state.visible}
                    title="Create a new User"
                    cancelText="Cancel"
                    onCancel={this.flipState}
                    footer={null}
                >
                    <Form
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            modifier: 'public',
                        }}
                        onFinish={this.createAUser}
                    >
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a valid email address',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="name" label="Name">
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Password">
                            <Input.Password
                                placeholder="input password" defaultValue=""
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        {/* <Button onClick={this.generatePass}> Generate Random</Button> */}
                        <Form.Item name="modifier" >
                            <Radio.Group>
                                <Radio value="public">Admin</Radio>
                                <Radio value="private">User</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create User
                        </Button>
                    </Form>
                </Modal>
                <div>
                    <Button type="primary"onClick={this.flipState}                         >
                        New User
                    </Button>
                </div>
            </div>
        );
    }
}
export default FormModal;


