import React, { Component } from 'react';
import { Button, Modal, Form, Input, DatePicker,
     Select,Tag, Tooltip, AutoComplete, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createTask, getAllUsers} from '../services/UserServices';
const { Option } = Select;
class TaskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            setVisible: false,
            password: '',
            data: [],
            tags: [],
            inputVisible: false,
            inputValue: '',
            editInputIndex: -1,
            editInputValue: '',
            lang:'',
            icovisible: false,
            isLoading: false,
            autoCompleteOptions:[],
            options:[],
            loadingCreateTask:false,
            isAdmin:false
        }
    }
    componentDidMount(){
        let role = localStorage.getItem('ROLE');
        if (role ==='ADMIN'){
            this.setState({isAdmin:true});
        }
    }
    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log("tags", tags);
        this.setState({ tags });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };
    onLangChange = (value)=>{
        switch(value){
            case 'english':
                this.setState({lang:'English'});
                return;
            case 'dutch':
                this.setState({lang:'Dutch'});
                return;
            default:
                return;
        }

    }

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };
    handleInputConfirm = () => {
        console.log("HI", this.state.tags);
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        //console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    handleEditInputChange = (e) => {
        console.log(e);
        this.setState({ editInputValue: e.target.value });
    };

    handleEditInputConfirm = () => {
        console.log("Confirm", this.state.editInputValue);
        this.setState(({ tags, editInputIndex, editInputValue }) => {
            const newTags = [...tags];
            newTags[editInputIndex] = editInputValue;

            return {
                tags: newTags,
                editInputIndex: -1,
                editInputValue: '',
            };
        });
    };

    handleUserSearch = (keyword) => {
        console.log("autoCompleteoptions", this.state.autoCompleteOptions);
        let options = this.state.autoCompleteOptions.filter((item)=> item.label?.toLowerCase().includes(keyword.toLowerCase())).slice(0,3);
        console.log(options);
        this.setState({options: options});
    };

    saveInputRef = (input) => {
        this.input = input;
    };

    saveEditInputRef = (input) => {
        this.editInput = input;
    };
    //end of tags functions
    postTask = (values) => {
        this.setState({loadingCreateTask: true});
        console.log(values.Assignee);
        if(!!values.Assignee){
            // eslint-disable-next-line
            let userId = this.state.autoCompleteOptions.filter(user => user.value == values.Assignee);
            values.Assignee = !!userId.length? userId[0]?.id:'';
        }
        else{
            delete values.Assignee;
        }

        values.Tags=this.state.tags;
        values.Language=this.state.lang;
        console.log("Values ", values )
        createTask(values)
        .then(res =>{
            console.log(res);
            Modal.success({content:"Task created successfully"});
            this.setState({visible:false});
        })
        .catch(err =>{
            console.log(err);
            Modal.error({content:"Could not create task"});
        })
        .finally(()=>{
            this.setState({loadingCreateTask: false});
        })
        
        /*
        
        "Title":"Demos Task",
        "Tags":["Tag111","Tag222"],
        "Website":"test.com",
        "Deadline":"11/11/2020",
        "Language":"English",
        "Assignee":0
        
        */


        /*let Taskobj={
            "Title":"React Test Task",
            "Tags":["Tag1","Tag2"],
            "Website":"entikker.com",
            "Deadline":"11/11/2021",
            "Language":"English",
            "Assignee":2,
            };
          
        Taskobj.Tags=this.state.tags;
        Taskobj.Title=values.title;
        Taskobj.Language=values.language;
        Taskobj.Website=values.website;
        Taskobj.Deadline=values.deadline;
        //finsihed auditing obj
        createTask(Taskobj).then( (res) =>{
            //console.log("Response recieved",res);
            this.setState({ visible: false })
        }
        ).catch(err =>{
                //console.log("trouble Creating Task " ,err);
            })*/
        
    }
    onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
      };

    onDateChange = (date, dateString) => {
        //console.log("Date Changed", date, dateString);
    }

    mapData = (data) => {
        let mappedData = data.map((userObj)=>{
            return {'label':userObj?.Name + "(" + userObj?.Email + ")", 'value': userObj.Email, 'id':userObj.Id}});
        this.setState({autoCompleteOptions: mappedData});

    }

    flipState = () => {
        let fs = this.state.visible;
        this.setState({ visible: !fs });
        this.setState({isLoading: true});
        getAllUsers()
        .then((res) => {
            console.log("users table", res.data?.data);
            //this.state.options.x();
            this.mapData(res.data?.data);
            //call function to map response to objs
            //this.mapData(res.data);
        })
        .catch((err) =>{
            Modal.error({content:'Failed to get list of users'});
            this.setState({visible:false})
            console.log(err);
        })
        .finally(() => {
            this.setState({isLoading:false});
        })
    }

    render() {
        return (
            <div >
                <Modal
                    visible={this.state.visible}
                    title="Create a new Task"
                    cancelText="Cancel"
                    onCancel={this.flipState}
                    footer={null}
                    confirmLoading={this.state.isLoading}
                    style={{minHeight:'300px'}}
                >

                    {this.state.isLoading && <div style={{width:'100%',textAlign:'center', minHeight:'200px'}}><Spin style={{textAlign:'center'}}/></div>}
                    {!this.state.isLoading &&
                    <Form
                        layout="vertical"
                        name="form_in_modal"
                        onFinish={this.postTask}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name="Title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter a title',
                                },
                            ]}
                        >
                            <Input placeholder="Task Title"/>
                        </Form.Item>
                            {/* move to another component  */}
                        <Form.Item name="Tags"
                            label="Keywords"
                            >
                                {this.state.tags.map((tag, index) => {
                                    if (this.state.editInputIndex === index) {
                                        return (
                                            <Input
                                                ref={this.saveEditInputRef}
                                                key={tag}
                                                size="small"
                                                className="tag-input"
                                                value={this.state.editInputValue}
                                                onChange={this.handleEditInputChange}
                                                onBlur={this.handleEditInputConfirm}
                                                onPressEnter={this.handleEditInputConfirm}
                                            />
                                        );
                                    }

                                    const isLongTag = tag.length > 20;

                                    const tagElem = (
                                        <Tag
                                            className="edit-tag"
                                            key={tag}
                                            closable={index !== 0}
                                            onClose={() => this.handleClose(tag)}
                                        >
                                            <span
                                                onDoubleClick={e => {
                                                    if (index !== 0) {
                                                        this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                                                            this.editInput.focus();
                                                        });
                                                        e.preventDefault();
                                                    }
                                                }}
                                            >
                                                {isLongTag ? `${tag.slice(0, 28)}...` : tag}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })}
                                {this.state.inputVisible && (
                                    <Input
                                        ref={this.saveInputRef}
                                        type="text"
                                        size="small"
                                        className="tag-input"
                                        value={this.state.inputValue}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputConfirm}
                                        onPressEnter={this.handleInputConfirm}
                                    />
                                )}
                                {!this.state.inputVisible && (
                                    <Tag className="site-tag-plus" onClick={this.showInput}>
                                        <PlusOutlined /> New Tag
                                    </Tag>
                                )}
                            
                        </Form.Item>
                        
                        <Form.Item name="Language" label="Language">
                            <Select
                                placeholder="Select a Language"
                                onChange={this.onLangChange}
                                allowClear
                            >
                                <Option value="english">English</Option>
                                <Option value="dutch">Dutch</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="Website" label="Website">
                            <Input placeholder="Website Name" />
                        </Form.Item>
                        <Form.Item name="ExternalPortal" label="External Portal Link">
                            <Input placeholder="format : www.sitename.siteextension" />
                        </Form.Item>
                        <Form.Item name="Repititions" label="Repititions">
                            <Input type="number" placeholder="Repititions for tags. ex. 3" />
                        </Form.Item>
                        <Form.Item name="Assignee" label="User">
                            <AutoComplete
                            options={this.state.options}
                            allowClear ={true}
                            placeholder="Enter User Name"
                            onSearch={this.handleUserSearch}
                            defaultActiveFirstOption={true}
                            >
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item name="Deadline" label="Deadline">
                            <DatePicker 
                            onChange={this.onDateChange} 
                            disabledDate={(current) => {
                              // Can not select days before today and today
                              return current && current.valueOf() < Date.now();
                            }} />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" loading={this.state.loadingCreateTask}>
                            Create Task
                        </Button>
                    </Form>
                    
                    }
                </Modal>
                {this.state.isAdmin? 
                    <div>
                        <Button style={styles.but} size={'large'} onClick={this.flipState}>Create A New Task</Button>
                    </div>
                    :<div></div>}
                
            </div>
        );
    }
}
export default TaskModal;

const styles = {
    list: {
        padding: 20
    },
    but: {
        marginTop: 25,
        background: '#52c41a',
        color: 'white'

    }
}
