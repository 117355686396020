import React, { Component } from "react";
import { Form, Input,message, Button,Layout,PageHeader} from 'antd';

import { changePassword } from "../services/UserServices";

const {  Content } = Layout;

class CPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username:"MYsfadf",
            oldPass:'value',
            newPass:'',
            isLoading:false
        }
    }
    componentDidMount() {
    }
    goBacks= ()=>{
        let hist= this.props.history;
        hist.goBack();
    }
    error = (txt) => {
        // console.log('error');
        
        message.error(txt);
    }
    onFinish = (values) => {
        console.log('Success:', values);
        let st=false
        this.setState({isLoading:true});
        if(values.password!==values.vpassword){
            this.error('New Passwords do not match please try again ');        
            this.setState({isLoading:false});
        }
        else{
            st=true;
        }
        if(st){
            //check for password requirements
        changePassword({
            "OldPassword":values.opassword,
            "NewPassword":values.vpassword
          })
          .then(function(resp, err) {
            if (err) {
              console.log("an error while changing password");
              console.log(err);
              this.error('Please Check old password ');
              //call error message 
            } 
            else if (resp) {
              if (resp.data.err) {
                console.log("response error", resp.data.err);
                this.error('Please Check old password ');
              } 
              else {
                console.log("response is cool ", resp.data);
              }
              console.log(resp);
            } 
            else {
              console.log("No response what so ever");
              this.error('Check internet connection ');
            }
          }).catch(err =>{
            console.log("trouble changing password " ,err);
            this.error('Cannot change password ');
        })
        .finally(()=>{
          this.setState({isLoading:false});
        })
        }
    };

    onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };
    render() {
        return (
            <div>
            <Content>
            <div style={{textAlign:"center"}}>
                <PageHeader
                    className="site-page-header"
                    onBack={this.goBacks}
                    title="Profile"
                    subTitle="Change password"
                />
            
                <div style={{ padding: 20,paddingTop:0 }}>
                <Form
                    name="basic"
                    labelCol={{span: 8,}}
                    wrapperCol={{span: 8,}}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off">
                    <Form.Item
                        label="Old Password"
                        name="opassword"
                        rules={[{
                                required: true,
                                message: 'Please input your old password!',
                            },]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{
                                required: true,
                                message: 'Please input your new password!',
                            },]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Verify Password"
                        name="vpassword"
                        rules={[{
                                required: true,
                                message: 'Please repeat your new password!',
                            },]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8,span: 8,}}>
                
                        <Button loading={this.state.isLoading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                </div>
            </div>
            </Content> 
            </div>
        );
    }
}
export default CPassword;