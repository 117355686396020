import React, { Component } from 'react';
import '../global.js'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'react-summernote/lang/summernote-nl-NL'; // you can import any other locale
 
// Import bootstrap(v3 or v4) dependencies
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import {  stripHtml } from '../utils/escapeHTML';
import { Button, Space } from 'antd';
import {CopyOutlined} from '@ant-design/icons';
 
class SNRichTextEditor extends Component {

  constructor(props){
    super(props);
    this.state = {
      value: undefined,
      valueForCopy:'',
      length: 0};
    //this.editor = createRef();
  }

  componentDidMount(){
    //const editor = this.editor.current;

  }

  fillParagraph = (note) =>{
    if(!!this.props.value){
      note.reset();
      // eslint-disable-next-line
      const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
      //console.log(this.props.value);
      if (this.props.value?.match(regex) !== null) {
        note.replace(this.props.value)
        let wordCount =stripHtml(this.props.value).split(" ").length;
        this.setState({length:wordCount});
      } else {
        note.insertText(this.props.value)
      }
      console.log("Changing value from summerNote ", this.props.value);
    }
    this.props.onChange(this.props.value);
  }
  countWords = (words) =>{
    let wordsPure = stripHtml(words);
    wordsPure =  wordsPure.replaceAll("\n", " ");
    let arr = stripHtml(wordsPure).split(" ");
    let wordCount = arr.filter((word)=> word.replace(/\s/g, '') !== "").length;

    this.setState({length:wordCount});
  }
  copyContent = ()=>{
    //let copyText=stripHtml(this.state.value);
    navigator.clipboard.writeText(this.state.valueForCopy);

  }
  onChange = (content, x) => {
    console.log("on change", content, x[0].innerText)
    this.setState({value:content});
    this.setState({valueForCopy:x[0].innerText})
    this.props.onChange(content);
    this.countWords(x[0].innerText);
  }
 

  handleOnBlur = (value)=>{
    this.countWords(this.state.valueForCopy);

    //console.log(value);
    //let content = value.target.innerHTML
    //this.setState({value:content});
    //this.props.onChange(content);
    
  }
  render() {
    return (
      <>
      <ReactSummernote
        value="Default value"
        onInit={this.fillParagraph}
        width={'100%'}
        options={{
          //lang: 'nl-NL',
          height: 350,
          dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']]
          ]
        }}
        onChange={this.onChange}
        onBlur={this.handleOnBlur}
        
        //ref={this.editor}
      />
      <Space style={{marginBottom:"50px"}}>
      <p>
        Word Count : {this.state.length}
      </p>
      <Button  type="primary" onClick={this.copyContent} icon={<CopyOutlined />}>
        Copy
      </Button>
      </Space>
      </>
    );
  }
}
 
export default SNRichTextEditor;