import React from 'react';
import { Result, Button } from 'antd';

import { Link } from "react-router-dom";

function Info() {
    //clear local storage to avoid future cache problems
    localStorage.clear();
    return (
        <Result
            title="You have been logged out succesfully"
            extra={<Link to='/'>
                <Button type="primary" key="console">
                  Go To HomePage
                 </Button></Link>  
            }        />
    );
}
export default Info;