import React, { Component } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import '../css/home.css';
import logo from './img/Logo.svg';
import { logout } from '../services/UserServices';
import { connect } from "react-redux";
const { Header } = Layout;
const StyledLink = styled(Link)`
  color: Blue;
  text-decoration: none;
  margin: 5px;
  position: relative;
`;

class MyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      color: 'light',
      isAdmin: false,
      isLoggedIn: false,
      lang:'',
      uid:'0'
    }
  }
  componentDidMount() {
    //console.log("Navbar mounted", this.props);
    
    let globalLang= localStorage.getItem("LANG") ||'EN';
    
    if(globalLang ==='EN'){
      this.setState({lang:'EN'});
    }
    else if(globalLang ==='NL'){
      this.setState({lang:'NL'});
    }
    if (this.props.theme === 'dark') {
      this.setState({ color: this.props.theme });
    }
    let role = localStorage.getItem('ROLE');
    if (role ==='ADMIN'){
        this.setState({isAdmin:true});
    }
    // console.log(localStorage.getItem('NAME'));
    if(localStorage.getItem('NAME')!==null){
      this.setState({isLoggedIn:true});
    }
    
    this.setState({uid:localStorage.getItem('UID')})
    // console.log(this.state.isAdmin);

  }
  componentDidUpdate(prevProps) {
    //console.log("did it update?x")
    // Typical usage (don't forget to compare props):
    if (this.props.theme !== prevProps.theme) {
      this.setState({ color: this.props.theme }, this.componentDidMount);
      this.setState({uid:localStorage.getItem('UID')})
    }
  }
  handleClick = (e) => {
    if(e.key ==='lang'){
      //flip language state 
      // should be in redux or i18n
      // console.log('click ', e.key);
      // this.setLanguage();
    }
    // this.setState({ current: e.key });
  };
  setLanguage =() =>{
    if(this.state.lang==='EN'){
      this.setState({lang:'NL'});
    }
    else{
      this.setState({lang:'EN'});
    }
    console.log("Set language here",this.state.lang);
    localStorage.setItem("LANG",this.state.lang);
  }
  signout = () => {
    // console.log("Log out Nav");
    // console.log('Clearing Store');
    this.props.clearStore();

    logout().then(
      function (resp, err) {
        if (err) {
          //console.log("an error");
          //console.log(err);
        }
        else if (resp) {
          if (resp.data.err) {
            //console.log("response error", resp.data.err);
            this.error();
          }
          else {
            //console.log("User is logged out successfully");
          }
        }
      }
    ).catch(
      err => {
        //console.log("trouble logging out ", err);
      }
    )
  }
  render() {
    return (
      <div>
        <Header className="navbar-w" theme={this.state.color} >
          <Menu onClick={this.handleClick}  style={{ textAlign: "right", }} mode="horizontal" >
            
            {this.state.isLoggedIn &&<>
            <Menu.Item key="Users" disabled={!this.state.isAdmin}>
              <StyledLink to='/users'>
               {(this.state.lang ==='EN')? "Users":"Gebruikers"} 
              </StyledLink>
            </Menu.Item>
            <Menu.Item key="Profile">
              <Dropdown overlay={() => <Menu>
                <Menu.Item>
                  <StyledLink to='changePassword'>
                    Change Password
                  </StyledLink>
                </Menu.Item>
                <Menu.Item>
                  <StyledLink to={`/profile/${this.state.uid}`}>
                    Update profile
                  </StyledLink>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                  <StyledLink to='/logout' onClick={this.signout}>
                    Logout
                  </StyledLink>
                </Menu.Item>
              </Menu>} placement="bottomRight">
                <Button type="text" > 
               {this.state.lang ==='EN'? "Profile":"Profiel"}  </Button>
              </Dropdown>
            </Menu.Item>
              </>}
            <Menu.Item key="Home">
              <a target="_blank" rel="noopener noreferrer" href="/">
                <img src={logo} alt="logo" width="60px" />
              </a>
            </Menu.Item>
          </Menu>
        </Header>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    clearStore: ()=>{
      dispatch({type: "CLEAR_STORE"});
    }
  }
}
export default connect(null,mapDispatchToProps) (MyHeader);