import React, { useState } from 'react';
import { Button, message } from 'antd';
import { uploadTaskFile } from '../../services/AdminServices';

export const FileUpload = () => {

  // for onchange event
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState('');
  
  // onchange event
  const handlePdfFileChange = async (e) => {
    let selectedFile = e.target.files[0];
    console.log("uploaded file name",selectedFile)
    if (selectedFile) {
      if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        let reader = new FileReader();
        // console.log(wb)
        // console.log("reading file")
        reader.readAsBinaryString(selectedFile);
        reader.onloadend = async (e) => {

          // console.log("file loaded ",e.target.result);
          setExcelFile(e.target.result);
          setExcelFileError('');

        }
      }
      else {
        setExcelFile(null);
        setExcelFileError('Please select valid excel file');
        // message.error(`Please select valid excel file`);
      }
    }
    else {
      console.log('select your file');
    }
  }
  
  // form submit
  const handlePdfFileSubmit = async (e) => {
    e.preventDefault();
    if (excelFile !== null) {
     
        uploadTaskFile(excelFile).then(resp =>{
          if (resp.data.err) {
            console.log("response error", resp.data.err);
          }
          else {
            // console.log("Task Created from task successfully");
            message.success(`Tasks Created from task successfully`);
          }
        }).catch((err) => {
            console.log("trouble Creating File Tasks",err);
            message.error(`Error : ` + err.response.data.message);
          }
        );
    }
  }

  return (
    <div className='landing' style={{ width: '800px' }}>

      <form className='form-group' onSubmit={handlePdfFileSubmit}>
        <input type="file" className='form-control'
          required onChange={handlePdfFileChange}
        />

        {excelFileError && <div className='error-msg'>{excelFileError}</div>}

        <Button type="primary"disabled={excelFileError} htmlType="submit">
          Confirm Uploads
        </Button>
      </form>


    </div>
  )
}

export default FileUpload;
