import React, { Component } from 'react';
import { Table, Space, Layout, Popconfirm,PageHeader,message,Button } from 'antd';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FormModal from '../components/FormModal';
import '../css/user.css';
import { getAllUsers } from '../services/UserServices';
import { deleteUser } from '../services/AdminServices';
const { Footer, Content } = Layout;

const StyledLink = styled(Link)`
  color: #5f55b5;
  text-decoration: none;
  margin: 5px;
  position: relative;
`;
const StyledLinkTwo = styled(Link)`
  color: #2f22b2;
  text-decoration: none;
  margin: 5px;
  position: relative;
`;


class Users extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // render: text => <a rel="noopener noreferrer" href="/">{text}</a>,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
        
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <StyledLink to={{ pathname: '/users/manage/' + record.key, state: { name: record.key } }}>Manage {record.name}</StyledLink>
                        <StyledLinkTwo to={{ pathname: '/users/tasks/' + record.key, state: { name: record.key } }}>View {record.name} Tasks</StyledLinkTwo>
                        <Popconfirm title="Are you sure you want to delete this user？"
                        onConfirm={() => this.removeUser(record.key,record.name)} okText="Yes" cancelText="No">
                          <Button type="link" style={{color:'#ff4d4f'}}> Delete</Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];
        this.state = {
            loading: true,
            tabData: [],
            lang:'EN'
            
        }
    }
    componentDidMount() {
        let role = localStorage.getItem('ROLE');
        if (role !== 'ADMIN') {
            //re route
            let hist = this.props.history;
            hist.goBack();
        }
        getAllUsers().then((res) => {
            console.log("users table", res.data);
            //call function to map response to objs
            this.mapData(res.data);
        })
            .catch((err) => {
                console.log("We have an error getting users", err);
            });
    }
    componentDidUpdate(prevProps) {
        console.log("did it update?x")
        // Typical usage (don't forget to compare props):
        if (this.props.language !== prevProps.language) {
          this.setState({ lang: this.props.language }, this.componentDidMount);
        }
      }
    goBacks= ()=>{
        let hist= this.props.history;
        hist.goBack();
    }
    removeUser =(userId)=>{
        console.log("deleting user",userId);
        
        return new Promise((resolve,reject) =>{
            deleteUser(userId).then((res)=>{
                if(res){
                    console.log("The response",res);
                    message.info("User Removed successfully");
                    this.setState({
                        tabData: this.state.tabData.filter((item) => item.key !== userId)
                    })
                }
            })
            .catch(err=>{
                console.log("error deleting user",err)
                message.error("Server Error, error code:"+ err);
            })
            .finally(() =>{
                resolve();
            })
        });
        
        
    }
    mapData = (mylist) => {
        let list = mylist.data;
        console.log("pend Data", list);
        var exmp = {
            key: 28,
            role: "ADMIN",
            name: "Admin Test",
            email: "mohamedtarek9922@gmail.com",
        };
        var newData = [];
        for (var i = 0; i < list.length; i++) {
            var item = list[i];
            exmp.key = item.Id;
            exmp.name = item.Name;
            exmp.role = item.Role;
            exmp.email = item.Email;
            let z = exmp;
            newData.push(z);
            exmp = {
                key: 28,
                role: "ADMIN",
                name: "Admin Test",
                email: "mohamedtarek9922@gmail.com",
            };
        }
        console.log("New Table Data", newData);
        this.setState({ tabData: newData });
        this.setState({ loading: false });
    }
    render() {
        return (
            <div className="mid">
                <Layout>
                    <PageHeader
                    className="site-page-header"
                    onBack={this.goBacks}
                    title={this.state.lang==='EN' ? "User":'Gebruikers'}
                    subTitle="Users Table"
                    />
                    <Content className="cont">

                        <Table scroll={{x:500}} columns={this.columns} dataSource={this.state.tabData} />
                        <FormModal />
                    </Content>
                    <Footer >Created with Love by : Out of the Box solutions</Footer>
                </Layout>
            </div>
        );
    }
}
export default Users;